import { FC } from 'react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import DesktopComponentBase from 'src/components/DesktopComponent';
import { decoListAtom } from 'src/stores/deco/atom';
import { EFFECT_GROUP_DESCRIPTION_CROWDIN_KEY, decoEffectGroupNameCrowdinKey } from 'src/types/Deco';
import EffectButton from './EffectButton';
import Gap from 'src/components/Gap';
import NewBadge from './NewBadge';
import { isNewEffectGroup } from './utils';
const DesktopComponent = styled(DesktopComponentBase)`
  max-height: 440px;
  flex: 1;
  flex-wrap: nowrap;
  padding-bottom: 20px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;
const SectionWrap = styled.div``;
const SectionDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};
`;
const SectionHeader = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:has(${SectionDescription}) {
    padding-bottom: 8px;
  }
`;
const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};
`;
const FilterList = styled.div`
  padding-bottom: 12px;
  flex-direction: row;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: 1;
`;
const DesktopEffects: FC = () => {
  const decoList = useAtomValue(decoListAtom);
  const {
    t
  } = useTranslation();
  return <DesktopComponent data-sentry-element="DesktopComponent" data-sentry-component="DesktopEffects" data-sentry-source-file="DesktopEffects.tsx">
      {decoList.map(effectGroup => {
      const effectGroupDescriptionKey = EFFECT_GROUP_DESCRIPTION_CROWDIN_KEY[effectGroup.groupId];
      return <SectionWrap key={effectGroup.groupId}>
            <SectionHeader>
              <SectionTitle>
                {t(decoEffectGroupNameCrowdinKey[effectGroup.groupId])}
                {isNewEffectGroup(effectGroup.groupId) && <>
                    <Gap width={4} />
                    <NewBadge />
                  </>}
              </SectionTitle>
              {effectGroupDescriptionKey && <SectionDescription>
                  {t(effectGroupDescriptionKey)}
                </SectionDescription>}
            </SectionHeader>
            <FilterList>
              {effectGroup.effects.map(effect => <EffectButton key={effect.effectId} effect={effect} />)}
            </FilterList>
          </SectionWrap>;
    })}
    </DesktopComponent>;
};
export default DesktopEffects;