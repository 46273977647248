import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import RecentMatchCounter from 'src/components/Counter/RecentMatchCounter';
import Gap from 'src/components/Gap';
const Container = styled.div`
  margin-top: 24px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
`;
const GreenDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({
  theme
}) => theme.color.green500};
`;
const Counter = styled(RecentMatchCounter)`
  font-size: 18px;
  color: ${({
  theme
}) => theme.color.white};
`;
const Desc = styled.span`
  font-size: 18px;
  color: ${({
  theme
}) => theme.color.white};
`;
const MatchCounter = () => {
  const {
    t
  } = useTranslation();
  return <Container data-sentry-element="Container" data-sentry-component="MatchCounter" data-sentry-source-file="MatchCounter.tsx">
      <GreenDot data-sentry-element="GreenDot" data-sentry-source-file="MatchCounter.tsx" />
      <Gap width={6} data-sentry-element="Gap" data-sentry-source-file="MatchCounter.tsx" />
      <Counter data-sentry-element="Counter" data-sentry-source-file="MatchCounter.tsx" />
      <Desc data-sentry-element="Desc" data-sentry-source-file="MatchCounter.tsx">&nbsp;{t('online__matching_now')}</Desc>
    </Container>;
};
export default MatchCounter;