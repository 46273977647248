import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import { closeAntmanWarningAtom } from 'src/stores/match/atoms';
import { hexToRgb } from 'src/utils/common';
import BaseImage from 'src/components/Image';
const Root = styled.div`
  position: absolute;
  background-color: rgba(${({
  theme
}) => hexToRgb(theme.color.gray900)}, 0.7);
  backdrop-filter: blur(60px);
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Image = styled(BaseImage)`
  width: 240px;
  height: 240px;
  margin-bottom: 38px;
  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-bottom: 24px;
    width: 160px;
    height: 160px;
  }
`;
const Button = styled.button`
  margin-bottom: 16px;
  box-sizing: border-box;
  max-width: 100%;
  width: 279px;
  background: rgba(${({
  theme
}) => hexToRgb(theme.color.black)}, 0.5);
  border-radius: 100px;

  padding: ${({
  theme
}) => theme.isRtl ? '15px 21px 15px 35px' : '15px 35px 15px 21px'};
  ${({
  theme
}) => theme.screenSize.desktop} {
    padding: ${({
  theme
}) => theme.isRtl ? '19px 26px 19px 40px' : '19px 40px 19px 26px'};
    width: 346px;
  }
  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-bottom: 10px;
  }
  display: flex;
  flex-direction: row;
  align-items: cetner;
  justify-content: space-between;
  align-items: center;
`;
const ButtonIcon = styled.img`
  height: 16px;
  ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 4px;
  ${({
  theme
}) => theme.screenSize.desktop} {
    height: 20px;
  }
`;
const ButtonText = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  ${({
  theme
}) => theme.screenSize.desktop} {
    font-size: 18px;
  }
`;
const ReportButton = styled(Button)`
  color: ${({
  theme
}) => theme.color.pink500};
`;
const ContinueButton = styled(Button)`
  color: ${({
  theme
}) => theme.color.green500};
`;
const NextButton = styled(Button)`
  display: none;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: flex;
  }
  color: ${({
  theme
}) => theme.color.white};
`;
const Dimmed = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(${({
  theme
}) => hexToRgb(theme.color.black)}, 0.5);
`;
const LoadingIndicator = styled(LoadingIndicatorBase)`
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: 32px;
    height: 32px;
  }
`;
interface Props {
  handleClickNext: () => void;
}
const AntmanWarning: FC<Props> = ({
  handleClickNext
}) => {
  const closeAntmanWarning = useSetAtom(closeAntmanWarningAtom);
  const {
    t
  } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async (shouldReport: boolean) => {
    try {
      setIsLoading(true);
      await closeAntmanWarning(shouldReport);
    } finally {
      setIsLoading(false);
    }
  };
  return <Root data-sentry-element="Root" data-sentry-component="AntmanWarning" data-sentry-source-file="AntmanWarning.tsx">
      {isLoading && <Dimmed>
          <LoadingIndicator size={56} />
        </Dimmed>}
      <Image src='/images/match/imgPlaceholderShield.png' alt='Video Blured' data-sentry-element="Image" data-sentry-source-file="AntmanWarning.tsx" />
      <ReportButton type='button' onClick={() => handleClick(true)} data-sentry-element="ReportButton" data-sentry-source-file="AntmanWarning.tsx">
        <ButtonIcon src='/images/icons/icShieldReportRed.svg' alt='Report & End' data-sentry-element="ButtonIcon" data-sentry-source-file="AntmanWarning.tsx" />
        <ButtonText data-sentry-element="ButtonText" data-sentry-source-file="AntmanWarning.tsx">{t('BLUR_ABUSE_REPORT_AND_END')}</ButtonText>
      </ReportButton>
      <ContinueButton type='button' onClick={() => handleClick(false)} data-sentry-element="ContinueButton" data-sentry-source-file="AntmanWarning.tsx">
        <ButtonIcon src='/images/icons/icDiscover.svg' alt='Conitnue without blur' data-sentry-element="ButtonIcon" data-sentry-source-file="AntmanWarning.tsx" />
        <ButtonText data-sentry-element="ButtonText" data-sentry-source-file="AntmanWarning.tsx">{t('BLUR_ABUSE_CONTINUE')}</ButtonText>
      </ContinueButton>
      <NextButton type='button' onClick={handleClickNext} data-sentry-element="NextButton" data-sentry-source-file="AntmanWarning.tsx">
        <ButtonIcon src='/images/icons/icPerson.svg' alt='Go to Next Match' data-sentry-element="ButtonIcon" data-sentry-source-file="AntmanWarning.tsx" />
        <ButtonText data-sentry-element="ButtonText" data-sentry-source-file="AntmanWarning.tsx">{t('VIDEOCHAT_MATCH_NEXT_WEB')}</ButtonText>
      </NextButton>
    </Root>;
};
export default AntmanWarning;