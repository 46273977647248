import { FC, HtmlHTMLAttributes, useContext } from 'react';
import styled from '@emotion/styled';
import { ModalContentContext } from 'src/context/ModalContext/ModalContentContext';
import { hexToRgb } from 'src/utils/common';
import { ModalDim } from '.';
const ModalHeaderLayout = styled.div`
  gap: 8px;
  box-sizing: border-box;
  padding: 24px 24px 8px;
  min-height: 58px;
  z-index: 1;

  display: flex;
  border-bottom: 1px solid transparent;
  flex-direction: column;
  position: relative;

  /* ModalInner가 flex이기 때문에, flex로직에 의해 Header의 min-height가 의도대로 동작하지 않을 수 있음
  해당 문제를 해결하기 위해 flex-shrink: 0;을 추가해서, 축소를 방지함 */
  flex-shrink: 0;

  ${({
  theme
}) => theme.screenSize.mobile} {
    min-height: 58px;
    padding: 24px 24px 8px;
  }
`;
export const HeaderDim = styled.div<{
  isShow: boolean;
}>`
  ${({
  isShow
}) => ModalDim(isShow)}
  height: 24px;
  bottom: -24px;
  background: linear-gradient(
    180deg,
    var(--background-color) 0%,
    rgba(${({
  theme
}) => hexToRgb(theme.color.gray900)}, 0) 100%
  );
`;
const ModalHeader: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const {
    isTopReached
  } = useContext(ModalContentContext);
  return <ModalHeaderLayout {...props} data-sentry-element="ModalHeaderLayout" data-sentry-component="ModalHeader" data-sentry-source-file="ModalHeader.tsx">
      {children}
      {/* max-height 넘어가면 하단 딤노출 */}
      <HeaderDim isShow={!isTopReached} data-sentry-element="HeaderDim" data-sentry-source-file="ModalHeader.tsx" />
    </ModalHeaderLayout>;
};
export default ModalHeader;