import {
  EffectRenderer,
  EffectType,
} from '@hyperconnect/effects/dist/EffectRenderer';
import store from 'src/stores';
import {
  handleDecoUnsupportAtom,
  sendGestureEffectTriggerEventAtom,
} from 'src/stores/deco/atom';
import { DecoEffect, EffectRendererName } from 'src/types/Deco';
import { ControlledPromise } from 'src/utils/controlled-promise';
import MediaStreamPipe from 'src/utils/media/pipes/base';
import * as Sentry from '@sentry/nextjs';

class EffectRendererPipe extends MediaStreamPipe {
  // SSR 불가능해서 런타임에 생성
  private static effectRenderer: EffectRenderer | null;

  private static initPromise: ControlledPromise<boolean> | null = null;

  /**
   * 이펙트 적용/해제 관련 로직이 동시 호출되면 이펙트 라이브러리 내에서 충돌 발생
   * 따라서 해당 task queue로 관련된 로직이 순차적으로 실행하여 원하는 렌더링 결과 보장
   */
  private static effectQueue: Promise<any> = Promise.resolve();

  public static async initialize() {
    if (EffectRendererPipe.initPromise) {
      return EffectRendererPipe.initPromise.promise;
    }

    EffectRendererPipe.initPromise = new ControlledPromise<boolean>();

    try {
      const effectRenderer = new EffectRenderer();
      const isInitSuccess = await effectRenderer.initialize(
        `/mediapipe/tasksvision`,
        `/mediapipe/face_landmarker.task`
      );

      if (isInitSuccess) {
        EffectRendererPipe.effectRenderer = effectRenderer;
      }
      EffectRendererPipe.initPromise.resolve(isInitSuccess);
    } catch (err) {
      EffectRendererPipe.initPromise.resolve(false);
    }
    return EffectRendererPipe.initPromise.promise;
  }

  public static removeAllEffects() {
    EffectRendererPipe.effectRenderer?.removeAll();
  }

  private static enqueueTask<T>(task: () => Promise<T>): Promise<T> {
    const taskPromise = EffectRendererPipe.effectQueue.then(task);
    EffectRendererPipe.effectQueue = taskPromise.catch(() => {});
    return taskPromise;
  }

  private static getEffectType = (effect: DecoEffect) => {
    if (!effect.attributes) return null;
    switch (effect.attributes.effectRenderer[0]) {
      case EffectRendererName.LUT_FILTER: {
        return EffectType.LUTFilter;
      }
      case EffectRendererName.LIQUIFY:
      case EffectRendererName.STRETCH: {
        return EffectType.FaceDistortion;
      }
      case EffectRendererName.SKIN_SMOOTH: {
        return EffectType.FaceRetouch;
      }
      case EffectRendererName.TWO_D_STICKER:
      case EffectRendererName.THREE_D_STICKER:
      case EffectRendererName.MASK: {
        return EffectType.LegacyHeadgear;
      }
      case EffectRendererName.BACKGROUND:
      case EffectRendererName.BACKGROUND_BLUR: {
        return EffectType.BackgroundEffect;
      }
      case EffectRendererName.GESTURE: {
        return EffectType.Gesture;
      }
      default:
        return null;
    }
  };

  public static async removeEffect(effect: DecoEffect) {
    const effectType = EffectRendererPipe.getEffectType(effect);
    if (effectType === null) return;

    return EffectRendererPipe.enqueueTask(async () => {
      EffectRendererPipe.effectRenderer?.removeEffect(
        effectType,
        effect.effectId
      );
    });
  }

  public static async setEffect(
    effect: DecoEffect,
    resourceData: ArrayBuffer | null
  ) {
    return EffectRendererPipe.enqueueTask(async () => {
      const isInitSuccess = await EffectRendererPipe.initialize();
      if (!isInitSuccess || !EffectRendererPipe.effectRenderer) return false;

      const effectType = EffectRendererPipe.getEffectType(effect);
      if (effectType === null) {
        EffectRendererPipe.removeAllEffects();
        return true;
      }

      try {
        const onTriggerCallback =
          effectType === EffectType.Gesture
            ? () => {
                store.set(sendGestureEffectTriggerEventAtom, effect);
              }
            : undefined;

        const result = await EffectRendererPipe.effectRenderer.setEffect(
          effectType as any,
          resourceData,
          effect.effectId,
          onTriggerCallback
        );

        if (!result) return false;

        switch (effectType) {
          case EffectType.FaceRetouch:
            EffectRendererPipe.effectRenderer.setEffectStrength(
              effectType,
              1.0
            );
            break;
          case EffectType.BackgroundEffect:
            EffectRendererPipe.effectRenderer.setEffectStrength(
              effectType,
              1.0
            );
            break;
        }

        return true;
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    });
  }

  public async renderVideo() {
    const inputVideoElement = this.videoEl;
    const outputCanvasElement = this.canvasEl;

    if (!outputCanvasElement || !inputVideoElement) {
      return;
    }

    if (EffectRendererPipe.initPromise === null) {
      EffectRendererPipe.initialize();
    }

    this.render = async () => {
      if (
        !inputVideoElement ||
        !outputCanvasElement ||
        inputVideoElement.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA
      ) {
        return;
      }

      if (
        EffectRendererPipe.initPromise?.ended &&
        EffectRendererPipe.effectRenderer
      ) {
        const { videoWidth, videoHeight } = inputVideoElement;
        outputCanvasElement.width = videoWidth;
        outputCanvasElement.height = videoHeight;
        try {
          EffectRendererPipe.effectRenderer.render(
            inputVideoElement,
            outputCanvasElement
          );
        } catch (err) {
          Sentry.captureException(err);
          store.set(handleDecoUnsupportAtom);
        }
      } else {
        this.drawOriginalVideo();
      }
    };
  }
}

export default EffectRendererPipe;
