import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { hexToRgb } from 'src/utils/common';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import IcSendBase from 'src/assets/images/icSend.svg';
const SendButton = styled.button`
  position: absolute;
  top: 50%;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 2px;
  transform: translateY(-50%);
  display: none;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
`;
const RootStyle = styled.div`
  display: none;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: flex;
  }
  flex-grow: 1;
`;
const ChatInput = styled.input`
  box-sizing: border-box;
  height: 100%;
  padding: 10px 12px;
  color: ${({
  theme
}) => theme.color.white};
  opacity: 0.7;
  font-size: 13px;
  line-height: 16px;
  background: rgba(${({
  theme
}) => hexToRgb(theme.color.black)}, 0.3);
  border-radius: 20px;
  border: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${({
  theme
}) => theme.color.white};
    text-shadow: 0px 1px 2px rgba(${({
  theme
}) => theme.color.black}, 0.3);
  }
  &:focus {
    outline: none;
    ${({
  theme
}) => theme.isRtl ? 'padding-left' : 'padding-right'}: 42px;
  }
`;
const ChatInputRow = styled.form`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  &:focus-within,
  &:active {
    justify-content: space-between;
    ${ChatInput} {
      display: inline-flex;
      border: 1px solid ${({
  theme
}) => theme.color.green500};
      & + ${SendButton} {
        display: inline-flex;
      }
    }
  }
`;
const IcSendWrap = styled.div`
  background-color: ${({
  theme
}) => theme.color.green500};
  width: 27.2px;
  height: 27.2px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IcSend = styled(IcSendBase)`
  path {
    fill: ${({
  theme
}) => theme.color.black};
  }
`;
const HideChatButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  ${({
  theme
}) => theme.isRtl ? 'margin-right' : 'margin-left'}: 8px;

  border-radius: 20px;
  background-color: rgba(${({
  theme
}) => hexToRgb(theme.color.black)}, 0.2);
`;
const HideIcon = styled.img<{
  isHide: boolean;
}>`
  transition: transform 0.3s ease-out;
  ${({
  isHide
}) => isHide && 'transform: rotate(-180deg);'}
`;
export interface ChatInputProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  value: string;
  onChangeText: (text: string) => void;
  onSubmit: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isHide?: boolean;
  onClickHide?: () => void;
}
const ChatInputMobile: FC<ChatInputProps> = ({
  onChangeText,
  value,
  onSubmit,
  onFocus,
  onBlur,
  className,
  isHide = false,
  onClickHide
}) => {
  const {
    t
  } = useTranslation();
  const device = useCssMediaDevice();
  return <RootStyle className={className} data-sentry-element="RootStyle" data-sentry-component="ChatInputMobile" data-sentry-source-file="index.tsx">
      <ChatInputRow onSubmit={event => {
      event.preventDefault();
      onSubmit();
    }} onFocus={onFocus} onBlur={onBlur} data-sentry-element="ChatInputRow" data-sentry-source-file="index.tsx">
        {/* eslint-disable-next-line styled-components-a11y/no-autofocus */}
        <ChatInput placeholder={t('ROOM__CHAT_INPUT_HINT')} value={value} onChange={e => onChangeText(e.target.value)} autoFocus={device === 'mobile'} data-testid='chat-tablet-input' data-sentry-element="ChatInput" data-sentry-source-file="index.tsx" />
        <SendButton type='submit' onTouchEnd={e => {
        e.preventDefault();
        onSubmit();
      }} data-sentry-element="SendButton" data-sentry-source-file="index.tsx">
          <IcSendWrap data-sentry-element="IcSendWrap" data-sentry-source-file="index.tsx">
            <IcSend data-sentry-element="IcSend" data-sentry-source-file="index.tsx" />
          </IcSendWrap>
        </SendButton>
      </ChatInputRow>
      {onClickHide && <HideChatButton onClick={onClickHide} role='button' tabIndex={0}>
          <HideIcon alt='Hide chat button' src='/images/icons/icChevronDown.svg' isHide={isHide} />
        </HideChatButton>}
    </RootStyle>;
};
export default ChatInputMobile;