import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import IcCloseSvg from 'src/assets/images/icDecoClose.svg';
import ModalLayoutBase, { MobileModalType, ModalContent as ModalContentBase, ModalHeader as ModalHeaderBase, bottomModal } from 'src/components/ModalLayout';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { decoVidRefAtom, isShowDecoRedDotAtom } from 'src/stores/deco/atom';
import { handleDecoStreamVideoAtom, handleStreamVideoAtom, localVideoToDecoViewAtom } from 'src/stores/match/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalNavButtonType, ModalType } from 'src/types/Modal';
import DesktopEffects from './DesktopEffects';
import MobileEffects from './MobileEffects';
import { mouseHoverCss } from 'src/utils/styled';
import RemoveEffectButton from './EffectButton/RemoveEffectButton';
import EffectStringGuide from './EffectStringGuide';
import useEffectCoachMark from 'src/hooks/useEffectCoachmark';
const ModalLayout = styled(ModalLayoutBase)`
  max-height: calc(var(--vh) * 90);
  max-width: 458px;

  ${({
  theme
}) => theme.screenSize.tablet} {
    z-index: 2;
    max-width: var(--100vw);
    ${bottomModal}// 태블릿 뷰에서도 Bottom 모달 레이아웃 적용 목적
  }
`;
const DesktopPreviewWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: none;
  }
`;
const DesktopPreviewVideo = styled.canvas`
  object-fit: cover;
  width: 100%;
  height: 268px;
  transform: scaleX(-1);
  border-radius: 8px;
  margin: 16px 0;
`;
const DesktopEffectStringGuide = styled(EffectStringGuide)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ModalTitle = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  flex: 1;
  color: ${({
  theme
}) => theme.color.white};
`;
const ModalContent = styled(ModalContentBase)`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({
  theme
}) => theme.screenSize.tablet} {
    padding: 8px 0 0 0;
  }
`;
const ModalHeader = styled(ModalHeaderBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CloseButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: transparent;
  border-radius: 20px;

  ${({
  theme
}) => mouseHoverCss(`
      background-color: ${theme.color.gray100__dkGray150};
      opacity: 0.4;
    `)}
`;
const CloseButtonTitle = styled.p`
  color: ${({
  theme
}) => theme.color.black__dkWhite};
  font-size: 20px;
  font-weight: 700;
`;
const CloseButtonWrap = styled.div`
  position: absolute;
  z-index: 10;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  ${CloseButtonTitle} {
    display: none;
  }

  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}:16px;

  ${({
  theme
}) => theme.screenSize.tablet} {
    position: fixed;

    ${CloseButtonTitle} {
      display: block;
    }
    ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: 16px;
  }
`;
const EffectsModal = () => {
  const decoVideoRef = useAtomValue(decoVidRefAtom);
  const device = useCssMediaDevice();
  const setIsShowRedDot = useSetAtom(isShowDecoRedDotAtom);
  const {
    coachMarkType,
    closeCoachMark
  } = useEffectCoachMark();
  const handleDecoEffect = useSetAtom(handleDecoStreamVideoAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const handleStreamVideo = useSetAtom(handleStreamVideoAtom);
  const localVideoToDecoView = useAtomValue(localVideoToDecoViewAtom);
  const [isShowCloseButton, setIsShowCloseButton] = useState(false);
  const {
    t
  } = useTranslation();
  const isDesktop = device === 'desktop';
  useEffect(() => {
    setIsShowRedDot(false);
  }, [setIsShowRedDot]);
  useEffect(() => {
    if (coachMarkType !== null) {
      closeCoachMark();
    }
  }, [coachMarkType, closeCoachMark]);
  useEffect(() => {
    handleDecoEffect();
    handleStreamVideo();
  }, [handleDecoEffect, handleStreamVideo]);
  useEffect(() => () => {
    localVideoToDecoView?.stopRendering();
  }, [localVideoToDecoView]);
  return <>
      <ModalLayout isDim={isDesktop} navButtonType={ModalNavButtonType.BACK} mobileType={MobileModalType.BOTTOM_SHEET} onAnimationEnd={() => {
      setIsShowCloseButton(true);
    }} data-testid='Effect-Studio-Modal' data-sentry-element="ModalLayout" data-sentry-source-file="EffectsModal.tsx">
        <RemoveEffectButton data-sentry-element="RemoveEffectButton" data-sentry-source-file="EffectsModal.tsx" />
        {(isShowCloseButton || isDesktop) && <CloseButtonWrap>
            <CloseButton onClick={() => closeModal(ModalType.DECO_STUDIO)} data-testid='Mobile-Effect-Modal-Close-Button'>
              <IcCloseSvg />
            </CloseButton>
            <CloseButtonTitle>{t('deco_studio_title')}</CloseButtonTitle>
          </CloseButtonWrap>}
        {isDesktop && <ModalHeader>
            <ModalTitle>{t('deco_studio_title')}</ModalTitle>
          </ModalHeader>}
        <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="EffectsModal.tsx">
          <DesktopPreviewWrap data-sentry-element="DesktopPreviewWrap" data-sentry-source-file="EffectsModal.tsx">
            <DesktopPreviewVideo ref={decoVideoRef} data-testid='Effect-Studio-Modal-Preview' data-sentry-element="DesktopPreviewVideo" data-sentry-source-file="EffectsModal.tsx" />
            <DesktopEffectStringGuide data-testid={'Desktop-Effect-String-Guide'} data-sentry-element="DesktopEffectStringGuide" data-sentry-source-file="EffectsModal.tsx" />
          </DesktopPreviewWrap>
          {isDesktop ? <DesktopEffects /> : <MobileEffects />}
        </ModalContent>
      </ModalLayout>
    </>;
};
export default EffectsModal;