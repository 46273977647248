import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import useEvent from 'src/hooks/useEvent';
import color from 'src/styles/color';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
const RootStyle = styled.div<{
  backgroundColor: string;
  color: string;
}>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  width: 280px;
  height: 52px;
  border-radius: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  padding: 14px 18px;
  margin-bottom: 8px;
  cursor: pointer;
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: 100%;
  }
`;
const IcGoogle = styled.img`
  margin-top: -2px;
`;
const Label = styled.span`
  flex: 1;
  text-align: center;
  font-size: 15px;
`;
export enum LoginBrandName {
  PHONE = 'PHONE',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
  APPLE = 'Apple',
}
interface BrandLoginButtonProps {
  brand: LoginBrandName;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}
const IconCss = ({
  theme
}: {
  theme: Theme;
}) => css`
  margin-top: -2px;
  width: 21px;
  height: 20px;
  fill: ${theme.color.white};
`;
const IcPhone = styled(dynamic(() => import('src/assets/images/icPhone.svg')))`
  ${IconCss}
`;
const IcFacebook = styled(dynamic(() => import('src/assets/images/imgBrandFB.svg')))`
  ${IconCss}
`;
const IcApple = styled(dynamic(() => import('src/assets/images/imgBrandApple.svg')))`
  ${IconCss}
  fill: #0D0D0D;
`;
const Info: { [key in LoginBrandName]: {
  icon: React.ReactNode;
  backgroundColor: string;
  fontColor: string;
  label: string; // crowdin key 대체 예정
} } = {
  [LoginBrandName.PHONE]: {
    icon: <IcPhone />,
    backgroundColor: color.black,
    fontColor: color.dkGray970,
    label: 'Continue with Phone'
  },
  [LoginBrandName.FACEBOOK]: {
    icon: <IcFacebook />,
    backgroundColor: color.brandFacebook,
    fontColor: color.dkGray970,
    label: 'Continue with Facebook'
  },
  [LoginBrandName.GOOGLE]: {
    icon: <IcGoogle src='/images/icons/imgBrandGoogle.svg' alt='google' />,
    backgroundColor: color.gray30,
    fontColor: color.gray900,
    label: 'Continue with Google'
  },
  [LoginBrandName.APPLE]: {
    icon: <IcApple />,
    backgroundColor: color.gray30,
    fontColor: color.gray900,
    label: 'Continue with Apple'
  }
};
const BrandLoginButton: FC<BrandLoginButtonProps> = ({
  brand,
  onClick,
  ...props
}) => {
  const pushEvent = useEvent();
  const info = Info[brand];
  return <RootStyle {...props} onClick={e => {
    e.preventDefault();
    onClick(e);
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_METHOD,
      eventParams: {
        Method: brand.toLowerCase()
      }
    });
  }} color={info.fontColor} backgroundColor={info.backgroundColor} role='button' tabIndex={0} data-sentry-element="RootStyle" data-sentry-component="BrandLoginButton" data-sentry-source-file="index.tsx">
      {info.icon}
      <Label data-sentry-element="Label" data-sentry-source-file="index.tsx">{info.label}</Label>
    </RootStyle>;
};
export default BrandLoginButton;
export type { BrandLoginButtonProps };