import { FC, useMemo } from 'react';
import { DecoEffect, DecoGroupName } from 'src/types/Deco';
import ToggleEffectButton from './ToggleEffectButton';
import SelectEffectButton from './SelectEffectButton';
import { useAtomValue } from 'jotai';
import { effectGroupMapAtom } from 'src/stores/deco/atom';
interface Props {
  effect: DecoEffect;
}
const EffectButton: FC<Props> = ({
  effect
}) => {
  const effectGroupMap = useAtomValue(effectGroupMapAtom);
  const groupId = useMemo(() => {
    return effectGroupMap.get(effect.effectId);
  }, [effect.effectId, effectGroupMap]);
  const isToggleEffectButton = useMemo(() => {
    const TOGGLE_EFFECT_GROUP_IDS = [DecoGroupName.GESTURE];
    return groupId && TOGGLE_EFFECT_GROUP_IDS.includes(groupId);
  }, [groupId]);
  if (groupId === undefined) return null;
  if (isToggleEffectButton) {
    return <ToggleEffectButton effect={effect} />;
  } else {
    return <SelectEffectButton effect={effect} />;
  }
};
export default EffectButton;