import { useSetAtom } from 'jotai';
import { FC, useMemo } from 'react';
import ReportModal from 'src/components/Report/ReportModal';
import useEvent from 'src/hooks/useEvent';
import { blockPeerMediaAtom, reportMatchAtom } from 'src/stores/match/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { MatchInfo, ReportMatchMethod } from 'src/types/Match';
import { ReportEventParam, ReportProfileCardType, ReportType } from 'src/types/report';
import useGenderPreference from 'src/components/Match/Preference/Gender/useGenderPreference';
interface Props {
  matchInfo: MatchInfo;
  peerVideoCaptureBlob: Blob | null;
  eventParams: ReportEventParam;
}
const MatchReportModal: FC<Props> = ({
  matchInfo,
  peerVideoCaptureBlob,
  eventParams
}) => {
  const reportMatch = useSetAtom(reportMatchAtom);
  const blockPeerMedia = useSetAtom(blockPeerMediaAtom);
  const {
    genderMatchFilterOption
  } = useGenderPreference();
  const isUsingGenderFilter = (genderMatchFilterOption?.gemCost || 0) > 0;
  const pushEvent = useEvent();
  const reportTypes = useMemo(() => {
    return Object.values(ReportType).filter(type => type === ReportType.FAKE_GENDER_ABUSE ? isUsingGenderFilter : true);
  }, [isUsingGenderFilter]);
  const onReport = async (reportType: ReportType) => {
    await reportMatch({
      file: peerVideoCaptureBlob,
      reportMethod: ReportMatchMethod.IN_MATCH,
      reportType
    });
    pushEvent({
      eventName: EVENT_NAME.REPORT_USER,
      eventType: EVENT_TYPE.REPORT,
      eventParams: {
        ...eventParams,
        action_category: 'action'
      }
    });
  };
  return <ReportModal onClose={() => blockPeerMedia(false)} reportTypes={reportTypes} cardId={matchInfo.matchId} cardType={ReportProfileCardType.MATCH} visibleProfileImageOption={matchInfo.peerProfile.profileImageCount > 0} onReport={onReport} eventParams={eventParams} data-sentry-element="ReportModal" data-sentry-component="MatchReportModal" data-sentry-source-file="Modal.tsx" />;
};
export default MatchReportModal;