import styled from '@emotion/styled';
export const RootStyle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  ${({
  theme
}) => theme.screenSize.tablet} {
    color: ${({
  theme
}) => theme.color.white};
    opacity: 0.8;
    font-size: 13px;
  }
`;
export const BadgeWrap = styled.div`
  display: inline-block;
`;
export const Nickname = styled.div<{
  width?: number;
}>`
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  ${({
  width
}) => width && `
    max-width: ${width}px;
  `};
  text-overflow: ellipsis;
`;