import * as Sentry from '@sentry/nextjs';
import { AxiosRequestConfig } from 'axios';
import { atom } from 'jotai';
import { RESET, selectAtom } from 'jotai/utils';
import jwtDecode from 'jwt-decode';
import randomString from 'randomstring';
import ErrorAccountsModal from 'src/components/ErrorAccountsModal';
import LoginModal from 'src/components/LoginModal';
import { removeDecoEffectAtom } from 'src/stores/deco/atom';
import { eventLoginModalVisitorTriggerAtom, eventMutateAtom } from 'src/stores/event/atoms';
import { initialMatchFilterSelections, isVideoObjectFitCoverAtom, matchFilterSelectionsAtom, mobileLayoutAtom } from 'src/stores/match/atoms';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { hashtagsAtom, profileAtom } from 'src/stores/profile/atoms';
import { LoginModalType, loginModalTypeAtom, nextStepAtom, resetRegisterAtom } from 'src/stores/register/atoms';
import { addStepsOnPhoneNumberLoginStepsAtom, retryWithSmsVerificationAtom, phoneNumberLoginStepNumberAtom, smsVerificationResponseAtom, phoneNumberLoginStepsAtom, emailVerificationAtom } from 'src/stores/phoneNumberLogin/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import { lastGetSegmentTimeAtom } from 'src/stores/user/atom';
import { getVipRatingAtom, vipRatingAtom } from 'src/stores/vip/atoms';
import { AzarUser, InventoryItem, RemoteConfig } from 'src/types/AzarUser';
import { Consent } from 'src/types/consent';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { GENDER, LoginType } from 'src/types/register';
import { ToastType } from 'src/types/Toast';
import { MatataToken, SocialToken } from 'src/types/Token';
import { client, ErrorAlertMessage, PunishedException, setDTID } from 'src/utils/api';
import { deepEqual } from 'src/utils/common';
import { ControlledPromise } from 'src/utils/controlled-promise';
import { getAtomWithCookies, getAtomWithStorage, reviveDateFromStorage } from 'src/utils/localStorageUtils';
import { acceptCmpConsentsAPI, getCmpConsentsAPI, getFeatureSettingsPopupAPI, getInventoryAPI, GetServersInfoAPI, loginEmailAPI, LoginEmailParams, loginOAuthAPI, LoginOAuthParams, loginWithPhoneNumberAPI, loginWithAccessTokenAPI, matataTokenAPI, refreshAccessTokenAPI, registerAPI, VisitorTokenAPI } from './apis';
import { LoginErrorType, LoginWithPhoneThrowAble } from 'src/stores/phoneNumberLogin/types';
import EmailVerificationSelectStep from 'src/components/LoginModal/PhoneNumberLogin/EmailVerificationSelectStep';
import NotExistEmailStep from 'src/components/LoginModal/PhoneNumberLogin/NotExistEmailStep';
import EmailAddressMatchStep from 'src/components/LoginModal/PhoneNumberLogin/EmailAddressMatchStep';
import AgeRestrictedModal from 'src/components/AgeRestrictedModal';
import { FeatureSettingPopup } from 'src/stores/user/types';
import SuspensionModal from 'src/components/SuspensionModal';
import { closePaymentPopupAtom } from 'src/stores/shop/atoms';
export const isDeviceAgeRestrictedAtom = getAtomWithCookies<boolean>('isDeviceAgeRestrictedByAge', false);
interface RetryPromiseInfo {
  promise: ControlledPromise<void>;
  preventReLogin?: boolean;
}
export const userJoinDateAtom = getAtomWithStorage<Date | null>('userJoinDate', null, {
  reviver: reviveDateFromStorage
});
const retryPromiseAtom = atom<RetryPromiseInfo[]>([]);
const fireRetryPromiseAtom = atom(null, async (get, set, retryPromises: RetryPromiseInfo[] | void) => {
  try {
    await Promise.allSettled((retryPromises || get(retryPromiseAtom)).map(({
      promise
    }) => promise.resolve()));
  } catch (_e) {
    // discard error
  }
  set(retryPromiseAtom, []);
});
const isRetryingAtom = atom<boolean>(false);
export const addRetryPromiseAtom = atom(null, (get, set, retryPromiseInfo: RetryPromiseInfo) => {
  set(retryPromiseAtom, get(retryPromiseAtom).concat([retryPromiseInfo]));
});
export const userDataAtom = getAtomWithStorage<AzarUser | undefined>('userDataV2', undefined);
export const azarTokenAtom = selectAtom(userDataAtom, userData => {
  if (!userData) {
    return undefined;
  }
  const {
    accessToken,
    refreshToken
  } = userData;
  return {
    accessToken,
    refreshToken
  };
}, deepEqual);
export const debugRemoteConfigAtom = getAtomWithStorage<RemoteConfig | null>('debugRemoteConfig', null);
export const remoteConfigAtom = atom(get => {
  const userData = get(userDataAtom);
  if (!userData) return;
  return {
    ...userData?.remoteConfig,
    ...(get(debugRemoteConfigAtom) || {})
  };
});
export const cmpConsentsAtom = getAtomWithStorage<Consent | undefined | null>('cmpConsentsPopup', undefined);
export const isGettingCmpConsentsAtom = atom(false);
export const getCmpConsentsAtom = atom(null, async (get, set) => {
  if (!get(azarTokenAtom)?.accessToken) return;
  if (get(isGettingCmpConsentsAtom)) return;
  if (!get(cmpConsentsAtom)) {
    try {
      set(isGettingCmpConsentsAtom, true);
      const response = await getCmpConsentsAPI();
      if (response.data.consent) {
        set(cmpConsentsAtom, response.data.consent);
      } else {
        set(cmpConsentsAtom, null);
      }
    } finally {
      set(isGettingCmpConsentsAtom, false);
    }
  }
});
export const acceptCmpConsentsAtom = atom(null, async (get, set) => {
  let code = get(cmpConsentsAtom)?.code;
  if (!code) {
    const response = await getCmpConsentsAPI();
    if (response.data.consent) {
      code = response.data.consent.code;
    }
  }
  if (code) {
    try {
      set(cmpConsentsAtom, null);
      await acceptCmpConsentsAPI(code);
    } catch (e) {
      return;
    }
  }
});
export const featureSettingsPopupsAtom = atom<FeatureSettingPopup[] | undefined>(undefined);
export const getFeatureSettingsPopupAtom = atom(null, async (get, set) => {
  if (!get(featureSettingsPopupsAtom) && get(azarTokenAtom)?.accessToken) {
    try {
      const response = await getFeatureSettingsPopupAPI();
      if (response.data.result.popups) {
        set(featureSettingsPopupsAtom, response.data.result.popups);
      }
    } catch (_e) {
      return;
    }
  }
});
export const isLoginLoadingAtom = atom<boolean>(false);
export const matataTokenAtom = getAtomWithStorage<MatataToken | undefined>('matataTokenV2', undefined);
export const DTIDAtom = getAtomWithStorage<string | undefined>('X-Azar-DTIDV2', undefined);
export const setDTIDAtom = atom(null, (get, set) => {
  get(DTIDAtom);
  if (get(DTIDAtom)) {
    setDTID(get(DTIDAtom));
  } else {
    const dtid = randomString.generate({
      //브라우저 고유값(기기값과 비슷한기능, SMS 중복전송 차단 등에 활용
      length: 8,
      charset: 'hex'
    }).toUpperCase();
    set(DTIDAtom, dtid);
    setDTID(dtid);
  }
});
export const iceServersAtom = getAtomWithStorage<AzarUser['iceServers']>('iceServersV2', []);
export const stompBrokerInfoAtom = getAtomWithStorage<AzarUser['stompBrokerInfo'] | void>('stompBrokerInfoV2', undefined);
// newStompBrokerInfo에 담아놓고 status < initial 일 때만 stomp 연결하도록 함
export const newStompBrokerInfoAtom = atom<AzarUser['stompBrokerInfo'] | void>(undefined);
const setServersInfoCountAtom = atom(0);
export const userInventoryAtom = getAtomWithStorage<InventoryItem[]>('userInventoryV2', []);
export const lastInventoryUpdateTimeAtom = getAtomWithStorage<Date | null>('lastInventoryUpdateTime', null, {
  reviver: reviveDateFromStorage
});
export const setVisitorTokenAtom = atom(null, async (get, set) => {
  if (get(azarTokenAtom)?.accessToken) {
    return;
  }
  const response = await VisitorTokenAPI();
  set(matataTokenAtom, response.data);
});
export const removeTokenAtom = atom(null, (get, set) => {
  set(userDataAtom, undefined);
  set(stompBrokerInfoAtom, undefined);
  set(iceServersAtom, []);
  set(userInventoryAtom, []);
  set(setVisitorTokenAtom);
  set(vipRatingAtom, null);
  set(profileAtom, undefined);
  set(hashtagsAtom, null);
  set(matchFilterSelectionsAtom, initialMatchFilterSelections);
  set(removeDecoEffectAtom);
  set(isVideoObjectFitCoverAtom, true);
  set(mobileLayoutAtom, 'DEFAULT');
  set(cmpConsentsAtom, undefined);
  set(featureSettingsPopupsAtom, undefined);
  set(closePaymentPopupAtom);
});
export const setServersInfoAtom = atom(null, async (get, set) => {
  const userData = get(userDataAtom);
  if (!userData) {
    return;
  }
  try {
    const retryCount = get(setServersInfoCountAtom);
    if (retryCount === 5) {
      set(removeTokenAtom);
      set(setServersInfoCountAtom, 0);
      return;
    }
    set(setServersInfoCountAtom, retryCount + 1);
    const {
      data
    } = await GetServersInfoAPI();
    set(iceServersAtom, data.iceServers);
    set(stompBrokerInfoAtom, data.stompBrokerInfo);
  } catch (e) {
    set(removeTokenAtom);
    set(setServersInfoCountAtom, 0);
    return;
  }
});
export const pushChannelNameAtom = selectAtom(userDataAtom, userData => userData?.pushChannelName, deepEqual);
export const userCountryCodeAtom = selectAtom(userDataAtom, userData => userData?.userProfile?.location?.countryCode || userData?.userProfile?.country, deepEqual);
export const tryLoginTypeAtom = atom<LoginType | undefined>(undefined);
export const socialTokenAtom = atom<SocialToken | undefined>(undefined);
export const getInventoryAtom = atom(null, async (get, set) => {
  const {
    data: {
      data
    }
  } = await getInventoryAPI();
  set(userInventoryAtom, data);
});
export const setMatataTokenAtom = atom(null, async (get, set) => {
  const azarToken = get(azarTokenAtom);
  if (!azarToken) {
    return;
  }
  const response = await matataTokenAPI();
  set(matataTokenAtom, response.data);
});
interface Token {
  exp: number;
}
export const renewAccessTokenAtom = atom(null, (get, set) => {
  if (get(isRetryingAtom)) {
    return;
  }
  set(isRetryingAtom, true);
  const userData = get(userDataAtom);
  const refreshToken = get(azarTokenAtom)?.refreshToken;
  if (!userData || !refreshToken) {
    set(removeTokenAtom);
    return;
  }
  refreshAccessTokenAPI(refreshToken).then(response => {
    if (response.data.accessToken && response.data.refreshToken) {
      set(userDataAtom, {
        ...userData,
        refreshToken: response.data.refreshToken || '',
        accessToken: response.data.accessToken || ''
      });
      set(fireRetryPromiseAtom);
    }
  }).catch(e => {
    if (e.response?.data.message === 'Token has been expired or revoked.') {
      set(removeTokenAtom);
      if (window.AzarJs) {
        Sentry.captureMessage(`Token has been expired or revoked. ${e}, ${userData.userId}, ${userData.accessToken}, ${refreshToken}`);
        alert('Sorry. Please Login again');
        window.AzarJs.closePopup();
      } else {
        const retryPromises = get(retryPromiseAtom);
        set(retryPromiseAtom, []);
        if (retryPromises.find(({
          preventReLogin
        }) => !!preventReLogin)) {
          return;
        }
        set(eventLoginModalVisitorTriggerAtom, EVENT_LOGIN_MODAL_VISITOR_TRIGGER.RENEW_ACCESS_TOKEN);
        set(showModalAtom, {
          key: ModalType.LOGIN,
          component: () => LoginModal({
            loginSuccessCallback: () => set(fireRetryPromiseAtom, retryPromises)
          })
        });
      }
    }
  }).finally(() => {
    set(isRetryingAtom, false);
  });
});
export const loginWithTokenAtom = atom(null, async (get, set) => {
  const azarToken = get(azarTokenAtom)?.accessToken;
  if (get(isRetryingAtom) || !azarToken) {
    return;
  }
  set(isRetryingAtom, true);
  try {
    const response = await loginWithAccessTokenAPI(azarToken);
    if (response.data.result?.accessToken) {
      const userData = get(userDataAtom);
      if (userData) {
        set(userDataAtom, {
          ...userData,
          refreshToken: response.data.result?.refreshToken,
          accessToken: response.data.result?.accessToken
        });
      }
      set(fireRetryPromiseAtom);
    }
  } catch (_e) {
    // TODO: sentry
  }
  set(isRetryingAtom, false);
});
export const handleAgeRestrictedLoginAttemptAtom = atom(null, (get, set) => {
  set(closeModalAtom, ModalType.LOGIN);
  set(showModalAtom, {
    key: ModalType.AGE_RESTRICTED,
    component: () => <AgeRestrictedModal />
  });
});
export const handleLoginResultAtom = atom(null, async (get, set, response: Partial<Awaited<ReturnType<typeof loginOAuthAPI> | ReturnType<typeof loginEmailAPI> | ReturnType<typeof loginWithPhoneNumberAPI>>>) => {
  const {
    data: {
      result: azarUser,
      error
    } = {}
  } = response;
  if (azarUser?.accessToken) {
    const storageToken = get(azarTokenAtom);
    let userData = azarUser;
    if (storageToken?.refreshToken) {
      const newTokenExp = jwtDecode<Token>(userData.refreshToken).exp;
      const storageTokenExp = jwtDecode<Token>(storageToken.refreshToken).exp;

      // TODO: 꼭 필요한지, useSession의 webview login 처리 로직 개선 검토
      // NOTE: 기존 토큰이 유효기간이 더 긴 경우 기존 토큰 유지
      if (storageTokenExp > newTokenExp) {
        userData = {
          ...userData,
          ...storageToken
        };
      }
    }
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.WEB,
      eventName: EVENT_NAME.LOGIN_SUCCESS,
      eventParams: {
        visitor_token: get(matataTokenAtom)?.visitorId,
        method: get(tryLoginTypeAtom)
      },
      options: {
        user_id: azarUser.userId
      }
    });
    set(userDataAtom, userData);
    set(lastGetSegmentTimeAtom, new Date());
    if (!window.AzarJs) {
      set(iceServersAtom, userData.iceServers);
      set(newStompBrokerInfoAtom, userData.stompBrokerInfo);
      set(userInventoryAtom, userData.inventoryItems);
    }
    try {
      await Promise.all([set(setMatataTokenAtom), set(getVipRatingAtom), ...(window.AzarJs ? [set(getInventoryAtom), set(setServersInfoAtom)] : [])]);
    } catch (e) {
      // vip rating feature off 대응
    }
  } else if (error) {
    const {
      data: {
        throwable
      },
      message
    } = error;
    set(removeTokenAtom);
    switch (message) {
      case 'BadCredentialsException':
        if ((throwable as ErrorAlertMessage).reason === 'username_not_found') {
          set(loginModalTypeAtom, LoginModalType.SOCIAL_REGISTER);
        } else {
          set(closeModalAtom, ModalType.LOGIN);
          set(showModalAtom, {
            key: ModalType.QR,
            component: ErrorAccountsModal
          });
        }
        return;
      case 'PunishedException':
        set(closeModalAtom, ModalType.LOGIN);
        set(showModalAtom, {
          key: ModalType.LOGIN_SUSPENSION,
          component: () => SuspensionModal({
            punishment: throwable as PunishedException
          })
        });
        return;
      case 'AzarIllegalAgeException':
        set(closeModalAtom, ModalType.LOGIN);
        set(showModalAtom, {
          key: ModalType.ERROR,
          component: () => ErrorAccountsModal({
            errorMessage: 'LOGIN__ERROR_AGE'
          })
        });
        return;
      // 2차 인증이 필요한 경우
      case 'NotVerifiedException':
        if (response.config) {
          set(retryWithSmsVerificationAtom, {
            config: response.config
          });
        }
        return;
      default:
        set(showToastAtom, {
          message: 'LOGIN__ERROR',
          type: ToastType.ERROR
        });
        break;
    }
  } else {
    if (window.AzarJs) {
      Sentry.captureMessage(`Unexpected LoginResponse, ${JSON.stringify(response.status)}, ${JSON.stringify(response.data)}`);
      alert('Sorry. Please login again');
      window.AzarJs.closePopup();
    } else {
      set(showToastAtom, {
        message: 'LOGIN__ERROR',
        type: ToastType.ERROR
      });
    }
  }
});
export const retryLoginWithPhoneVerifyAtom = atom(null, async (get, set, config: AxiosRequestConfig) => {
  try {
    const response = await client(config);
    set(handleLoginResultAtom, response);
  } catch (_e) {
    // 별도 핸들링 필요하지 않음
  } finally {
    set(closeModalAtom, ModalType.LOGIN);
  }
});
export const loginOAuthAtom = atom(null, async (get, set, params: Omit<LoginOAuthParams, 'method'>) => {
  const socialBrand = get(tryLoginTypeAtom);
  if (!params.accessToken || socialBrand === undefined) {
    set(isLoginLoadingAtom, false);
    return;
  }
  set(isLoginLoadingAtom, true);
  try {
    const response = await loginOAuthAPI({
      method: socialBrand,
      ...params
    });
    set(handleLoginResultAtom, response);
  } catch (_e) {
    set(closeModalAtom, ModalType.LOGIN);
  } finally {
    set(isLoginLoadingAtom, false);
  }
});
export const loginWithPhoneNumberAtom = atom(null, async (get, set) => {
  try {
    const smsVerificationResponse = get(smsVerificationResponseAtom);
    if (!smsVerificationResponse) {
      Sentry.captureMessage('loginWithPhoneNumberAtom called without smsVerificationResponse');
      return;
    }
    const {
      phoneId,
      phoneToken
    } = smsVerificationResponse;
    const response = await loginWithPhoneNumberAPI({
      phoneId,
      phoneToken
    });
    const {
      data: {
        result: azarUser,
        error
      } = {}
    } = response;
    //전화번호가입의 경우 에러메시지가 이메일 입력 모달에 나와야함

    // 1. 정상 로그인
    if (azarUser) {
      set(handleLoginResultAtom, response);
      set(phoneNumberLoginStepNumberAtom, 1);
      set(loginModalTypeAtom, LoginModalType.LOGIN);
      set(phoneNumberLoginStepsAtom, RESET);
      return;
    }

    // TODO: LoginWithPhoneThrowAble 캐스팅 코드 개선
    // 현재 rpcClient의 구현상, exceptionTypeName으로 error type을 좁힐 수 없음
    // https://hyperconnect.atlassian.net/browse/WEB-3277 에서 개선 예정
    const loginWithPhoneError = error?.data?.throwable as LoginWithPhoneThrowAble;

    // 2. 디바이스 변경 O

    // 2-1. 이메일 등록 X, 인증 X.
    if (error?.data.exceptionTypeName === LoginErrorType.DeviceTransferException && !loginWithPhoneError.verified) {
      set(addStepsOnPhoneNumberLoginStepsAtom, NotExistEmailStep);
    }

    // 2-2. 이메일 등록 O, 인증 X
    if (error?.data.exceptionTypeName === LoginErrorType.VerifyEmailNeedException && !loginWithPhoneError.verified) {
      set(addStepsOnPhoneNumberLoginStepsAtom, EmailVerificationSelectStep);
      set(emailVerificationAtom, {
        email: loginWithPhoneError.userEmail
      });
    }

    // 2-3. 이메일 등록 O, 인증 O
    if (error?.data.exceptionTypeName === LoginErrorType.VerifyEmailNeedException && loginWithPhoneError.verified) {
      set(emailVerificationAtom, {
        email: loginWithPhoneError.userEmail
      });
      set(addStepsOnPhoneNumberLoginStepsAtom, EmailAddressMatchStep);
    }

    /**
     * @see https://hpcnt.slack.com/archives/C02SQS5AJDR/p1732765338836649?thread_ts=1728871654.224449&cid=C02SQS5AJDR
     * 2-4. (구) 이메일 등록 X, 인증 X 케이스. 아직 발생할 여지가 있기 때문에 유지
     */
    if (error?.data.exceptionTypeName === 'com.azarlive.api.exception.AzarFeedbackException') {
      set(addStepsOnPhoneNumberLoginStepsAtom, NotExistEmailStep);
    }
    set(nextStepAtom);
  } catch (e) {
    set(resetRegisterAtom);
    set(closeModalAtom, ModalType.LOGIN);
    Sentry.captureMessage(`LoginSMSAPI Error, ${JSON.stringify(e)}`);
  }
});
export const loginEmailAtom = atom(null, async (get, set, params: LoginEmailParams) => {
  const response = await loginEmailAPI(params);
  set(handleLoginResultAtom, response);
});
export const signupEmailAtom = atom(null, async (get, set, params: LoginEmailParams) => {
  try {
    const response = await registerAPI({
      birthMonth: 11,
      birthDay: 22,
      birthYear: 1993,
      gender: GENDER.MALE,
      consents: {},
      ...params
    });
    set(handleLoginResultAtom, response);
    set(userJoinDateAtom, new Date());
  } catch (_e) {
    // 504 handle
  }
});
export const loadMatataTokenAtom = atom(null, (get, set) => {
  const matataToken = get(matataTokenAtom);
  const azarToken = get(azarTokenAtom);
  const isVisitor = !azarToken;
  const newTokenNeeded = !matataToken || matataToken.accessTokenExpiresAt < Date.now();
  if (!newTokenNeeded) return;
  if (isVisitor) {
    set(setVisitorTokenAtom);
  } else {
    set(setMatataTokenAtom);
  }
});
export const turnstileTokenAtom = atom<string | undefined>(undefined);
export const turnstileWidgetSiteKeyAtom = atom<string | undefined>(undefined);
export const turnstileWidgetOnSuccessAtom = atom(null, async (get, set, {
  token,
  callback
}: {
  token: string;
  callback: () => void | Promise<void>;
}) => {
  if (!token) return;
  set(turnstileTokenAtom, token);
  await callback();
  set(turnstileWidgetSiteKeyAtom, undefined);
  set(turnstileTokenAtom, undefined);
});