import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { matchUuidAtom } from 'src/stores/match/atoms';
import { hexToRgb } from 'src/utils/common';
const MatchUuidBottomDim = styled.div`
  z-index: 1;
  display: none;
  justify-content: flex-end;
  padding: 16px;
  align-items: flex-end;
  ${({
  theme
}) => theme.screenSize.desktop} {
    display: flex;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({
  theme
}) => `linear-gradient(180deg, rgba(${hexToRgb(theme.color.black)}, 0) 0%, rgba(${hexToRgb(theme.color.black)}, 0.4) 100%)`};
  height: 80px;
  border-radius: 0 0 20px 20px;
`;
const MatchUuid = styled.span`
  color: rgba(${({
  theme
}) => hexToRgb(theme.color.gray900__dkGray970)}, 0.4);
  font-family: 'Fira Code';
  font-size: 12px;
`;
const DesktopMatchUuid = () => {
  const matchUuid = useAtomValue(matchUuidAtom);
  return <MatchUuidBottomDim data-sentry-element="MatchUuidBottomDim" data-sentry-component="DesktopMatchUuid" data-sentry-source-file="DesktopMatchUuid.tsx">
      <MatchUuid data-sentry-element="MatchUuid" data-sentry-source-file="DesktopMatchUuid.tsx">{matchUuid}</MatchUuid>
    </MatchUuidBottomDim>;
};
export default DesktopMatchUuid;