import React, { FC, PropsWithChildren } from 'react';
import Gap from 'src/components/Gap';
import { ChatContainer, Icon, Profile, RootStyle } from './style';
interface ChatProps {
  iconSource?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}
const ChatLayout: FC<PropsWithChildren<ChatProps>> = ({
  iconSource,
  children,
  className,
  onClick
}) => <RootStyle onClick={onClick} hasClick={!!onClick} className={className} data-testid='chat-layout' data-sentry-element="RootStyle" data-sentry-component="ChatLayout" data-sentry-source-file="ChatLayout.tsx">
    <Profile data-sentry-element="Profile" data-sentry-source-file="ChatLayout.tsx">
      <Icon src={iconSource} alt={`ic${iconSource}`} data-sentry-element="Icon" data-sentry-source-file="ChatLayout.tsx" />
    </Profile>
    <Gap width={4} data-sentry-element="Gap" data-sentry-source-file="ChatLayout.tsx" />
    <ChatContainer data-sentry-element="ChatContainer" data-sentry-source-file="ChatLayout.tsx">{children}</ChatContainer>
  </RootStyle>;
export { ChatLayout };
export type { ChatProps };