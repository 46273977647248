import { FC } from 'react';
import styled from '@emotion/styled';
import { HEADER_HEIGHT, TABLET_HEADER_HEIGHT } from 'src/components/Header/style';
import ModalLayoutBase, { ModalLayoutProps } from 'src/components/ModalLayout';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import ShopComponent from '.';
const ModalLayout = styled(ModalLayoutBase)`
  position: fixed;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 24px;
  top: ${HEADER_HEIGHT};
  width: 468px;
  max-height: calc(var(--100vh) - ${HEADER_HEIGHT} - 24px);
  ${({
  theme
}) => theme.screenSize.tablet} {
    max-height: calc(var(--100vh) - ${TABLET_HEADER_HEIGHT} - 24px);
    right: 16px;
    top: ${TABLET_HEADER_HEIGHT};
  }
  ${({
  theme
}) => theme.screenSize.mobile} {
    max-height: var(--100vh);
    top: 0;
    left: 0;
    right: 0;
  }
`;
const ShopModal: FC<ModalLayoutProps> = ({
  ...props
}) => {
  const device = useCssMediaDevice();
  return <ModalLayout lockScroll={device === 'mobile'} data-testid='Shop-Modal' {...props} data-sentry-element="ModalLayout" data-sentry-component="ShopModal" data-sentry-source-file="ShopModal.tsx">
      <ShopComponent data-sentry-element="ShopComponent" data-sentry-source-file="ShopModal.tsx" />
    </ModalLayout>;
};
export default ShopModal;