import { useContext } from 'react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import Image from 'src/components/Image';
import { mobileLayoutAtom } from 'src/stores/match/atoms';
import { LanguageContext } from 'src/utils/language';
const WatermarkImage = styled(Image)<{
  isHalfLayout: boolean;
}>`
  width: auto;
  z-index: 1;
  ${({
  theme
}) => theme.screenSize.desktop} {
    ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: 24px;
    position: absolute;
    top: 16px;
    height: 36px;
  }
  ${({
  theme
}) => theme.screenSize.tablet} {
    height: 24px;
    ${({
  theme,
  isHalfLayout
}) => isHalfLayout ? `
      ${theme.isRtl ? 'left' : 'right'}: 16px;
      bottom: 12px;
      position: absolute;
    ` : `
        bottom: -8px;
        transform: translateY(100%);
        position: absolute;
    `}
  }
`;
const Watermark = () => {
  const {
    language
  } = useContext(LanguageContext);
  const mobileLayout = useAtomValue(mobileLayoutAtom);
  const isHalfLayout = mobileLayout === 'HALF';
  return <WatermarkImage src={`/images/logo/watermark${language === 'ar' || language === 'pt' ? `-${language}` : ''}.svg`} alt='watermark' supportImageSet={false} isBig={false} isHalfLayout={isHalfLayout} data-sentry-element="WatermarkImage" data-sentry-component="Watermark" data-sentry-source-file="Watermark.tsx" />;
};
export default Watermark;