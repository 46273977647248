import React, { useEffect, useMemo, useRef } from 'react';
import { KeenSliderPlugin, TrackDetails, useKeenSlider } from 'keen-slider/react';
import styled from '@emotion/styled';
import { useMeasure } from 'src/components/react-grid-drag/use-measure';

/**
 * WheelControls는 keen-slider example 코드를 참고함
 * @see https://keen-slider.io/examples#scroll-wheel-controls
 */
const WheelControls: KeenSliderPlugin = slider => {
  let touchTimeout: ReturnType<typeof setTimeout>;
  let position: {
    x: number;
    y: number;
  };
  let wheelActive = false;
  let wheelEventCountPerDrag = 0;
  function dispatch(e: WheelEvent, name: string) {
    position.x -= e.deltaX;
    position.y -= e.deltaY;
    slider.container.dispatchEvent(new CustomEvent(name, {
      detail: {
        x: position.x,
        y: position.y
      }
    }));
  }
  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY
    };
    dispatch(e, 'ksDragStart');
  }
  function wheel(e: WheelEvent) {
    dispatch(e, 'ksDrag');
    wheelEventCountPerDrag += 1;
  }
  function wheelEnd(e: WheelEvent) {
    dispatch(e, 'ksDragEnd');

    // 스크롤 휠을 가진 마우스를 사용할 때, 한 번의 휠 이벤트로 한 슬라이드씩 이동하도록 함
    if (wheelEventCountPerDrag === 1) {
      // animation이 진행중인 경우, next/prev가 무시되므로, duration을 0으로 설정하여 즉시 이동하도록 함
      const originalAnimation = slider.options.defaultAnimation;
      slider.options.defaultAnimation = {
        duration: 0,
        easing: t => t
      };
      if (e.deltaY > 0) {
        slider.next();
      } else if (e.deltaY < 0) {
        slider.prev();
      }
      slider.options.defaultAnimation = originalAnimation;
    }
    wheelEventCountPerDrag = 0;
  }
  function eventWheel(e: WheelEvent) {
    e.preventDefault();
    if (!wheelActive) {
      wheelStart(e);
      wheelActive = true;
    }
    wheel(e);
    clearTimeout(touchTimeout);
    touchTimeout = setTimeout(() => {
      wheelActive = false;
      wheelEnd(e);
    }, 50);
  }
  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false
    });
  });
};
const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const SlideInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-top: 1px solid rgba(68, 68, 68, 0.8);
  border-bottom: 1px solid rgba(68, 68, 68, 0.8);
`;
const Slides = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;
const SlideItem = styled.button<{
  selected: boolean;
}>`
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  font-family: arial, verdana, sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: ${({
  theme,
  selected
}) => selected ? theme.color.gray900__dkGray970 : theme.color.gray400__dkGray300};
  font-weight: ${({
  selected
}) => selected ? 700 : 400};
  cursor: pointer;
`;
export interface WheelOption {
  value: string;
  label: string;
}
export interface Props {
  options: WheelOption[];
  onChange: (option: WheelOption) => void;
  value?: WheelOption;
}
const DRAG_SPEED = 0.3;
const SLIDES_PER_VIEW = 9;

// WheelPicker 스크롤시, 회전 효과를 주기위한 threshold 값
const SLIDE_DEGREE_THRESHOLD = 18;
export default function WheelPicker({
  options,
  onChange,
  value
}: Props) {
  // keen slider 이벤트 핸들러에서 최신 onChange 함수를 참조하기 위한 ref
  const latestOnChange = useRef(onChange);
  useEffect(() => {
    latestOnChange.current = onChange;
  }, [onChange]);
  const [sliderState, setSliderState] = React.useState<TrackDetails | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    bounds: {
      height: containerHeight
    }
  } = useMeasure(containerRef);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      number: options.length,
      origin: 'center',
      perView: SLIDES_PER_VIEW
    },
    vertical: true,
    initial: options.findIndex(o => o.value === value?.value),
    loop: true,
    dragSpeed: defaultSpeed => {
      return defaultSpeed * DRAG_SPEED;
    },
    animationEnded: s => {
      latestOnChange.current(options[s.track.details.rel]);
    },
    detailsChanged: s => {
      setSliderState(s.track.details);
    },
    created: s => {
      containerRef.current = s.container as HTMLDivElement;
    },
    rubberband: false,
    mode: 'free-snap'
  }, [WheelControls]);
  const [radius, setRadius] = React.useState(0);
  React.useEffect(() => {
    setRadius(containerHeight / 2);
  }, [containerHeight]);
  useEffect(() => {
    if (slider.current && value) {
      slider.current.moveToIdx(options.findIndex(o => o.value === value?.value));
    }
  }, [options, value, slider]);
  const slideItems = useMemo(() => {
    if (!sliderState) return [];

    // 각 슬라이드의 중앙정렬을 위해 사용
    // 기본 0.5에서 slidesPerView에 따라 offset 조정
    const offset = 0.5 - 1 / SLIDES_PER_VIEW / 2;
    const selectedSlide = sliderState.slides[sliderState.rel];
    const values = options.map((_, index) => {
      const slide = sliderState.slides[index];
      const isHidden = slide.portion === 0;
      const isSelected = selectedSlide && selectedSlide.abs === slide.abs;
      const distance = slide ? (slide.distance - offset) * SLIDES_PER_VIEW : 0;
      const rotate = isHidden ? 180 : distance * SLIDE_DEGREE_THRESHOLD * -1;
      // iOS의 DatePicker(wheel)와 비슷한 원통 회전 효과를 주기 위한 스타일
      const style = {
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`
      };
      return {
        style,
        index,
        isSelected,
        isHidden
      };
    });
    return values;
  }, [options, radius, sliderState]);
  return <SlideWrapper ref={sliderRef} data-sentry-element="SlideWrapper" data-sentry-component="WheelPicker" data-sentry-source-file="Wheel.tsx">
      <SlideInner data-sentry-element="SlideInner" data-sentry-source-file="Wheel.tsx">
        <Slides data-sentry-element="Slides" data-sentry-source-file="Wheel.tsx">
          {slideItems.map(({
          style,
          isSelected,
          isHidden,
          index
        }) => !isHidden && <SlideItem style={style} selected={isSelected} key={index} onClick={() => {
          slider.current?.moveToIdx(index);
        }}>
                  {options[index].label}
                </SlideItem>)}
        </Slides>
      </SlideInner>
    </SlideWrapper>;
}