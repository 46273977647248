import { FC } from 'react';
import { Nickname, RootStyle } from './style';
interface ChatNickNameProps {
  label?: string;
  nicknameMaxWidth?: number;
}
const ChatNickName: FC<ChatNickNameProps> = ({
  label,
  nicknameMaxWidth,
  ...props
}) => <RootStyle {...props} data-sentry-element="RootStyle" data-sentry-component="ChatNickName" data-sentry-source-file="ChatNickName.tsx">
    <Nickname width={nicknameMaxWidth} data-sentry-element="Nickname" data-sentry-source-file="ChatNickName.tsx">{label}</Nickname>
  </RootStyle>;
export { ChatNickName };
export type { ChatNickNameProps };