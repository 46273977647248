import { useMemo } from 'react';
import useLogin from 'src/hooks/useLogin';
import { useTranslation } from 'react-i18next';
import { useGetConsentsAcceptedQuery } from 'src/hooks/useGetConsentsAcceptedQuery';
import { UserSettingType } from 'src/stores/user/types';

export const useGetTermsUrl = () => {
  const { t } = useTranslation();
  const { isLogin } = useLogin();
  const { data: consentsAccepted } = useGetConsentsAcceptedQuery();

  const termOfUse = useMemo(() => {
    const defaultTermsOfUseUrl = t('TERMS_OF_SERVICE');
    if (isLogin) {
      const { consentInfos } = consentsAccepted?.data?.result || {};
      const userTermsOfUse = consentInfos?.find(
        ({ type }) => type === UserSettingType.TERM_OF_USE
      );

      return userTermsOfUse?.url || defaultTermsOfUseUrl;
    }

    return defaultTermsOfUseUrl;
  }, [consentsAccepted?.data?.result, isLogin, t]);

  const privacyPolicy = useMemo(() => {
    const defaultPrivacyPolicyUrl = t('PRIVACY_POLICY');
    if (isLogin) {
      const { legalNotices, consentInfos } =
        consentsAccepted?.data?.result || {};
      const userPrivacyPolicy = [
        ...(consentInfos || []),
        ...(legalNotices || []),
      ].find(({ type }) => type === UserSettingType.PRIVACY_POLICY);

      return userPrivacyPolicy?.url || defaultPrivacyPolicyUrl;
    }

    return defaultPrivacyPolicyUrl;
  }, [consentsAccepted?.data?.result, isLogin, t]);

  return {
    privacyPolicy,
    termOfUse,
  };
};
