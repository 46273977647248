import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { userDataAtom } from 'src/stores/auth/atoms';
import useLogin from 'src/hooks/useLogin';
import { useCmpConsentsModal } from './useCmpConsentsModal';
import { useUpdatedConsentModal } from './useUpdatedConsentModal';
import { useFeatureSettingsPopupModal } from './useFeatureSettingsPopupModal';
import { openedModalsAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { useWebToAppModal } from './useWebToAppModal';
const useLoginUserOrderedModal = () => {
  const userData = useAtomValue(userDataAtom);
  const {
    isLogin
  } = useLogin();
  const isGuidelineModalOpened = useAtomValue(openedModalsAtom).some(modal => modal.key === ModalType.REGISTER_GUIDELINE);
  const {
    isResolved: isCmpResolved,
    show: showCmpModal
  } = useCmpConsentsModal();
  const {
    isResolved: isUpdatedConsentsResolved,
    show: showUpdatedConsents
  } = useUpdatedConsentModal();
  const {
    isResolved: isFeatureSettingsResolved,
    show: showFeatureSettings
  } = useFeatureSettingsPopupModal();
  const {
    show: showWebToAppModal
  } = useWebToAppModal();
  useEffect(() => {
    // 현재 해당 훅의 대상인 모달들은 웹클라이언트 전용 > 웹뷰에서는 노출 제한
    if (window.AzarJs) return;
    if (!isLogin || !userData || isGuidelineModalOpened) return;
    if (!isCmpResolved) {
      showCmpModal();
    } else if (!isUpdatedConsentsResolved) {
      showUpdatedConsents();
    } else if (!isFeatureSettingsResolved) {
      showFeatureSettings();
    } else {
      showWebToAppModal();
    }
  }, [isCmpResolved, isFeatureSettingsResolved, isLogin, isUpdatedConsentsResolved, showCmpModal, showFeatureSettings, showUpdatedConsents, userData, isGuidelineModalOpened, showWebToAppModal]);
};
export default useLoginUserOrderedModal;