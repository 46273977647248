import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import IcChevronDown from 'src/assets/images/icChevronDown.svg';
import Gap from 'src/components/Gap';
import { ButtonBase } from 'src/components/Match/styles';
import ResizingText from 'src/components/ResizingText';
import useCallbackWithCheckLogin from 'src/hooks/useCallbackWithCheckLogin';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import { showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { ModalProps } from './Modal';
import useEventLoginModalVisitorTrigger from 'src/hooks/useEventLoginModalVisitorTrigger';
export interface PreferenceButtonProps extends ModalProps, Pick<React.HTMLAttributes<HTMLButtonElement>, 'className'> {
  id: 'GENDER' | 'LOCATION';
  Modal: React.FC<ModalProps>;
}
const Button = styled.button`
  ${ButtonBase}
  font-size: 20px;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
  padding-left: 18px;
  padding-right: 18px;
`;
export const FilterIcon = styled(IcChevronDown)`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 16px;
    height: 16px;
  }
`;
const PreferenceButtonTextBase = styled(ResizingText)`
  ${({
  theme
}) => theme.screenSize.desktop} {
    flex-grow: 1;
    text-align: ${({
  theme
}) => theme.isRtl ? 'right' : 'left'};
    height: min-content;
  }
`;
export const PreferenceButtonText: FC<PropsWithChildren> = ({
  children
}) => {
  const device = useCssMediaDevice();
  const minFontSize = device === 'desktop' ? 16 : 12;
  return <PreferenceButtonTextBase minFontSize={minFontSize} maxLines={2} data-sentry-element="PreferenceButtonTextBase" data-sentry-component="PreferenceButtonText" data-sentry-source-file="Button.tsx">
      {children}
    </PreferenceButtonTextBase>;
};
const PreferenceButton: FC<PropsWithChildren<PreferenceButtonProps>> = ({
  id,
  className,
  children,
  Modal,
  ...modalProps
}) => {
  const pushEvent = useEvent();
  const showModal = useSetAtom(showModalAtom);
  const setEventLoginModalVisitorTrigger = useEventLoginModalVisitorTrigger();
  const handleClickSetting = useCallbackWithCheckLogin(() => {
    showModal({
      key: ModalType.MATCH_SETTING,
      component: () => <Modal {...modalProps} />
    });
  });
  const handleClick = () => {
    setEventLoginModalVisitorTrigger(EVENT_LOGIN_MODAL_VISITOR_TRIGGER[`${id}_BUTTON`]);
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${modalProps.landFrom}__CLICK_${id}FILTER`]
    });
    handleClickSetting();
  };
  return <Button onClick={handleClick} className={className} data-testid={`Match-${id}PreferenceButton-${modalProps.landFrom}`} data-sentry-element="Button" data-sentry-component="PreferenceButton" data-sentry-source-file="Button.tsx">
      {children}
      <Gap width={6} data-sentry-element="Gap" data-sentry-source-file="Button.tsx" />
      <FilterIcon data-sentry-element="FilterIcon" data-sentry-source-file="Button.tsx" />
    </Button>;
};
export default PreferenceButton;