import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import useLogin from 'src/hooks/useLogin';
import { isShowingCoachMarkAtom } from 'src/stores/match/atoms';
import { ToolButtonStringGuide } from './styles';
const Wrap = styled.div`
  position: relative;
`;
interface Props {
  guide: string;
  children: React.ReactNode;
}
const ButtonWithGuide: FC<Props> = ({
  guide,
  children
}) => {
  const isShowingCoachMark = useAtomValue(isShowingCoachMarkAtom);
  const {
    isLogin
  } = useLogin();
  return <Wrap data-sentry-element="Wrap" data-sentry-component="ButtonWithGuide" data-sentry-source-file="ButtonWithGuide.tsx">
      {children}
      {isLogin && !isShowingCoachMark && <ToolButtonStringGuide key={guide}>{guide}</ToolButtonStringGuide>}
    </Wrap>;
};
export default ButtonWithGuide;