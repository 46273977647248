import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { Wrap } from 'src/components/LoginModal/styles';
import ModalLayoutBase, { ModalFooter as ModalFooterBase, ModalHeader, ModalInner } from 'src/components/ModalLayout';
import { ModalHeaderTitle as ModalHeaderTitleBase } from 'src/components/ModalLayout';
import { ModalHeaderDescriptionRow } from 'src/components/ModalLayout';
import { MobileModalType } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalNavButtonType, ModalType } from 'src/types/Modal';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { useCmpConsentsModal } from 'src/hooks/useLoginUserOrderedModal/useCmpConsentsModal';
const ModalLayout = styled(ModalLayoutBase)`
  width: 500px;
  min-height: 600px
  height: auto;

  & > ${ModalInner} {
    min-height: inherit;
  }
`;
const ListContainer = styled.div`
  position: relative;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  :last-child {
    margin-bottom: 0;
  }
  background-color: ${({
  theme
}) => theme.color.gray30__dkGray50};
  padding: ${({
  theme
}) => theme.isRtl ? '14px 16px 14px 24px' : '14px 24px 14px 16px'};
  border-radius: 16px;
`;
const RowImage = styled.img`
  ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 16px;
`;
const RowRight = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};
`;
const RowDesc = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: ${({
  theme
}) => theme.color.white};
`;
const GuideLine = styled.p<{
  isCenter: boolean;
}>`
  display: flex;
  font-size: 14px;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  padding: 8px;
  a {
    text-decoration: underline;
    font-weight: 700;
    :visited {
      color: inherit;
    }
  }
  align-items: center;
  ${({
  theme
}) => theme.screenSize.mobile} {
    ${({
  isCenter
}) => isCenter && 'justify-content: center;'}
  }
  white-space: nowrap;
`;
const ModalHeaderTitle = styled(ModalHeaderTitleBase)`
  ${({
  theme
}) => theme.screenSize.mobile} {
    ${({
  mobileType,
  theme
}) => mobileType === MobileModalType.SCREEN_MODAL && (theme.isRtl ? 'margin-right' : 'margin-left') + ': 0px;'}
  }
`;
const ModalContent = styled(Wrap)`
  padding: 24px;
`;
const ModalFooter = styled(ModalFooterBase)`
  padding-top: 0;
  gap: 16px;
`;
const ConsentsWrapper = styled.div``;
const BioMetric = styled.div`
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  word-break: keep-all;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};

  ${({
  theme
}) => theme.screenSize.mobile} {
    font-size: 12px;
    line-height: 18px;
  }

  a {
    color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  }
`;
const rows = ['SIGNUP__COMMUNITY_TRUTH', 'SIGNUP__COMMUNITY_MANNER', 'SIGNUP__COMMUNITY_RESPECT', 'SIGNUP__COMMUNITY_MINOR'];
const RegisterGuidelineModal: FC = () => {
  const {
    t
  } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    guidelines,
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const bioMetric = guidelines?.find(({
    type
  }) => type === 'BIOMETRIC');
  const {
    show: showCmpModal
  } = useCmpConsentsModal();
  const handleClickNext = () => {
    closeModal(ModalType.REGISTER_GUIDELINE);
  };
  const pushEvent = useEvent();
  const tryLoginType = useAtomValue(tryLoginTypeAtom);
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_COMMUNITY,
      eventParams: {
        loginType: tryLoginType
      }
    });
  }, [pushEvent, tryLoginType]);
  useEffect(() => {
    showCmpModal();
  }, [showCmpModal]);
  return <ModalLayout isDim closeWhenClickOutside={false} navButtonType={ModalNavButtonType.NONE} data-sentry-element="ModalLayout" data-sentry-component="RegisterGuidelineModal" data-sentry-source-file="GuidelineModal.tsx">
      <ModalHeader data-testid='RegisterGuidelineModal' data-sentry-element="ModalHeader" data-sentry-source-file="GuidelineModal.tsx">
        <ModalHeaderTitle mobileType={MobileModalType.SCREEN_MODAL} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="GuidelineModal.tsx">
          {t('guidelines_title')}
        </ModalHeaderTitle>
        <ModalHeaderDescriptionRow data-sentry-element="ModalHeaderDescriptionRow" data-sentry-source-file="GuidelineModal.tsx">
          {t('guidelines_desc')}
        </ModalHeaderDescriptionRow>
      </ModalHeader>
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="GuidelineModal.tsx">
        <ListContainer data-sentry-element="ListContainer" data-sentry-source-file="GuidelineModal.tsx">
          {rows.map((key, index) => <Row key={key}>
              <RowImage src={`/images/icons/imgGuideline0${index + 1}.svg`} alt={t(`guidelines_list_title_${index + 1}`)} />
              <RowRight>
                <RowTitle>{t(`guidelines_list_title_${index + 1}`)}</RowTitle>
                <RowDesc>{t(`guidelines_list_desc_${index + 1}`)}</RowDesc>
              </RowRight>
            </Row>)}
        </ListContainer>
      </ModalContent>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="GuidelineModal.tsx">
        <ConsentsWrapper data-sentry-element="ConsentsWrapper" data-sentry-source-file="GuidelineModal.tsx">
          <GuideLine isCenter={consentsUiType === 'CONSENT_WEB_A'} dangerouslySetInnerHTML={{
          __html: t('guidelines_link_text')
        }} data-sentry-element="GuideLine" data-sentry-source-file="GuidelineModal.tsx" />
          {bioMetric && consentsUiType === 'CONSENT_WEB_B' && <BioMetric dangerouslySetInnerHTML={{
          __html: bioMetric.content
        }} />}
        </ConsentsWrapper>
        <Button onClick={handleClickNext} id='guidelines_button' data-testid='RegisterGuidelineStep-button-next' data-sentry-element="Button" data-sentry-source-file="GuidelineModal.tsx">
          {t('guidelines_button')}
        </Button>
      </ModalFooter>
    </ModalLayout>;
};
export default RegisterGuidelineModal;