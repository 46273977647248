import { useAtom, useAtomValue } from 'jotai';
import { ToolButton, ToolButtonIcon } from 'src/components/Match/Matching/Toolbar/styles';
import useCallbackWithCheckLogin from 'src/hooks/useCallbackWithCheckLogin';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import useEventLoginModalVisitorTrigger from 'src/hooks/useEventLoginModalVisitorTrigger';
import { eventMatchPageAtom, isVideoObjectFitCoverAtom, mobileLayoutAtom } from 'src/stores/match/atoms';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
export default function ObjectFitButton() {
  const [isVideoObjectFitCover, setIsVideoObjectFitCover] = useAtom(isVideoObjectFitCoverAtom);
  const mobileLayout = useAtomValue(mobileLayoutAtom);
  const pushEvent = useEvent();
  const device = useCssMediaDevice();
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const setEventLoginModalVisitorTrigger = useEventLoginModalVisitorTrigger();
  const handleClickObjectFit = useCallbackWithCheckLogin(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIEW,
      eventName: EVENT_NAME.MIRROR_CLICK_LETTERBOX,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: eventMatchPage,
        target: 'letterbox',
        width: device === 'desktop' ? 'long' : 'short',
        letterbox: isVideoObjectFitCover ? 'on' : 'off',
        ...(device !== 'desktop' && {
          split: mobileLayout === 'DEFAULT' ? 'off' : 'on'
        })
      }
    });
    setIsVideoObjectFitCover(!isVideoObjectFitCover);
  });
  const onClick = () => {
    setEventLoginModalVisitorTrigger(EVENT_LOGIN_MODAL_VISITOR_TRIGGER.OBJECT_FIT_BUTTON);
    handleClickObjectFit();
  };
  return <ToolButton onClick={onClick} data-sentry-element="ToolButton" data-sentry-component="ObjectFitButton" data-sentry-source-file="ObjectFitButton.tsx">
      <ToolButtonIcon src={`/images/match/${isVideoObjectFitCover ? 'icMinimize' : 'icMaximize'}.svg`} alt='camera-mode' data-sentry-element="ToolButtonIcon" data-sentry-source-file="ObjectFitButton.tsx" />
    </ToolButton>;
}