import { FC, PropsWithChildren } from 'react';
import Button, { ButtonProps as BaseButtonProps } from 'src/components/Button';
import { ButtonWrap, Container, Description, DescriptionContainer, LinkText, Title } from './styles';
type ButtonProps = Omit<BaseButtonProps, 'id'> & {
  text: string;
  'data-testid'?: string;
};
type Props = {
  eventId: string;
  title: string;
  description?: string;
  linkText?: string;
  link?: string;
  mainButtonProps: ButtonProps;
  subButtonProps?: ButtonProps;
  dataTestId?: string;
};
const Template: FC<PropsWithChildren<Props>> = ({
  eventId,
  title,
  description,
  linkText,
  link,
  children,
  mainButtonProps,
  subButtonProps,
  dataTestId
}) => {
  return <Container data-testid={dataTestId} data-sentry-element="Container" data-sentry-component="Template" data-sentry-source-file="Template.tsx">
      <DescriptionContainer data-sentry-element="DescriptionContainer" data-sentry-source-file="Template.tsx">
        <Title data-sentry-element="Title" data-sentry-source-file="Template.tsx">{title}</Title>
        {description && <Description>{description}</Description>}
        {children}
      </DescriptionContainer>
      <ButtonWrap data-sentry-element="ButtonWrap" data-sentry-source-file="Template.tsx">
        <Button {...mainButtonProps} id={`sms-login-${eventId}`} color={mainButtonProps.color ?? 'secondary'} data-sentry-element="Button" data-sentry-source-file="Template.tsx">
          {mainButtonProps.text}
        </Button>
        {subButtonProps && <Button {...subButtonProps} id={`sms-login-${eventId}-sub`} color={subButtonProps.color ?? 'tertiary'}>
            {subButtonProps.text}
          </Button>}
        {linkText && <LinkText href={link}>{linkText}</LinkText>}
      </ButtonWrap>
    </Container>;
};
export default Template;
export type { Props };