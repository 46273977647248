import styled from '@emotion/styled';
import { FC } from 'react';
import { IconBase } from 'src/styles/icons';
const Icon = styled.img`
  ${IconBase}
  ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 8px;
`;
type Props = {
  className?: string;
};
const LocationIconBase: FC<Props> = ({
  className
}) => <Icon className={className} src='/images/match/icLocation.png' alt='locationIcon' data-sentry-element="Icon" data-sentry-component="LocationIconBase" data-sentry-source-file="Icon.tsx" />;
export default LocationIconBase;