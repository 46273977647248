import { FC, useEffect } from 'react';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { ConsentsA } from './Consents/ConsentsA';
import { useSetAtom } from 'jotai';
import { nextStepAtom, requiredConsentsMapForRegisterAtom } from 'src/stores/register/atoms';

/**
 * 약관 동의 절차
 */
const TermAgreeStep: FC = () => {
  const moveNextStep = useSetAtom(nextStepAtom);
  const setRequiredConsentsMapForRegister = useSetAtom(requiredConsentsMapForRegisterAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType,
    consentsRequired
  } = consentsData?.data?.result || {};
  useEffect(() => {
    if (consentsUiType === 'CONSENT_WEB_B') {
      moveNextStep();
    }
  }, [consentsUiType, moveNextStep]);
  useEffect(() => {
    if (!consentsRequired) return;
    const requiredConsentsMap = consentsRequired.reduce((prev, consent) => {
      return {
        ...prev,
        [consent.type]: consent.consentId
      };
    }, {});
    setRequiredConsentsMapForRegister(requiredConsentsMap);
  }, [consentsRequired, setRequiredConsentsMapForRegister]);
  if (consentsUiType === 'CONSENT_WEB_A') {
    return <ConsentsA />;
  } else {
    return null;
  }
};
export default TermAgreeStep;