import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import { endMatchDisableShowAtom, nextMatchDisableShowAtom, statusAtom, stepAtom, swipeDisableSecondAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
const Container = styled.div<{
  step: number;
}>`
  height: ${({
  step
}) => step === 1 ? '0' : '88px'};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  transition: height 0.5s 0.5s;
  overflow: hidden;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: none;
  }
`;
const MatchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${({
  theme
}) => theme.color.white};
  width: 56px;
  height: 56px;
  background-color: ${({
  theme
}) => theme.color.gray60__dkGray100};
  border-radius: 16px;
`;
const MatchButtonTextContainer = styled.div<{
  position: 'left' | 'right';
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({
  position
}) => position === 'left' ? 'flex-start' : 'flex-end'};
  justify-content: center;
  height: 100%;
`;
const MatchButtonTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({
  theme
}) => theme.color.white};
`;
const EndMatchButtonText = styled.span``;
const ArrowRightIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const ActiveButtonCss = ({
  theme
}: {
  theme: Theme;
}) => css`
  ${MatchButton} {
    background-color: ${theme.color.gray900__dkGray970};
    color: ${theme.color.gray30__dkGray50};
    ${ArrowRightIcon} {
      filter: brightness(0);
    }
  }
  ${MatchButtonTextContainer} {
    ${MatchButtonTitle} {
      color: ${theme.color.gray700__dkGray700};
    }
  }
`;
const SwipeDisableText = styled.span<{
  isShow: boolean;
}>`
  ${({
  isShow
}) => !isShow && `
    display: none;
  `}
`;
const MatchButtonContainer = styled.button<{
  position: 'left' | 'right';
  isActive: boolean;
}>`
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: ${({
  position
}) => position === 'left' ? 'row' : 'row-reverse'};
  align-items: center;
  gap: 16px;
  :disabled {
    opacity: 0.5;
  }
  ${({
  theme
}) => theme.pointer.mouse} {
    &:not(:disabled):hover {
      ${ActiveButtonCss}
    }
  }
  ${({
  theme
}) => theme.pointer.touch} {
    &:not(:disabled):active {
      ${ActiveButtonCss}
    }
  }
  &:not(:disabled) {
    ${({
  isActive
}) => isActive && ActiveButtonCss}
  }
`;
const MatchButtonDescription = styled.span`
  font-family: 'Noto Sans';
  font-size: 13px;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
interface IDesktopMatchFooter {
  endMatch: () => void;
  nextMatch: () => void;
  isEscKeyPressed: boolean;
  isArrowRightKeyPressed: boolean;
}
const DesktopMatchFooter: FC<IDesktopMatchFooter> = ({
  endMatch,
  nextMatch,
  isEscKeyPressed,
  isArrowRightKeyPressed
}) => {
  const step = useAtomValue(stepAtom);
  const status = useAtomValue(statusAtom);
  const swipeDisableSecond = useAtomValue(swipeDisableSecondAtom);
  const endMatchDisableShow = useAtomValue(endMatchDisableShowAtom);
  const nextMatchDisableShow = useAtomValue(nextMatchDisableShowAtom);
  const enableSkipConnecting = useEnableRemoteConfig('enableSkipConnecting');
  const {
    t
  } = useTranslation();
  return <Container step={step} data-sentry-element="Container" data-sentry-component="DesktopMatchFooter" data-sentry-source-file="index.tsx">
      <MatchButtonContainer position='left' onClick={endMatch} data-testid='Match-Back-Desktop' disabled={!!endMatchDisableShow || !!enableSkipConnecting && status === STATUS.CONNECTING} isActive={isEscKeyPressed} data-sentry-element="MatchButtonContainer" data-sentry-source-file="index.tsx">
        <MatchButton data-sentry-element="MatchButton" data-sentry-source-file="index.tsx">
          {swipeDisableSecond > 0 && <SwipeDisableText data-testid='Match-Back-Desktop-disable' isShow={endMatchDisableShow}>
              {swipeDisableSecond}
            </SwipeDisableText>}
          {!endMatchDisableShow && <EndMatchButtonText>esc</EndMatchButtonText>}
        </MatchButton>
        <MatchButtonTextContainer position='left' data-sentry-element="MatchButtonTextContainer" data-sentry-source-file="index.tsx">
          <MatchButtonTitle data-sentry-element="MatchButtonTitle" data-sentry-source-file="index.tsx">{t('END_VIDEO_CHAT')}</MatchButtonTitle>
          <MatchButtonDescription data-sentry-element="MatchButtonDescription" data-sentry-source-file="index.tsx">
            {t('END_VIDEO_CHAT_KEY')}
          </MatchButtonDescription>
        </MatchButtonTextContainer>
      </MatchButtonContainer>
      <MatchButtonContainer position='right' onClick={nextMatch} data-testid='Match-Next-Desktop' disabled={!!nextMatchDisableShow || status < STATUS.CONNECTING || !!enableSkipConnecting && status === STATUS.CONNECTING} isActive={isArrowRightKeyPressed} data-sentry-element="MatchButtonContainer" data-sentry-source-file="index.tsx">
        <MatchButton data-sentry-element="MatchButton" data-sentry-source-file="index.tsx">
          {swipeDisableSecond > 0 && <SwipeDisableText data-testid='Match-Next-Desktop-disable' isShow={nextMatchDisableShow}>
              {swipeDisableSecond}
            </SwipeDisableText>}
          {!nextMatchDisableShow && <ArrowRightIcon src='/images/icons/icArrowRight.png' alt='next match' />}
        </MatchButton>
        <MatchButtonTextContainer position='right' data-sentry-element="MatchButtonTextContainer" data-sentry-source-file="index.tsx">
          <MatchButtonTitle data-sentry-element="MatchButtonTitle" data-sentry-source-file="index.tsx">{t('NEXT_VIDEO_CHAT')}</MatchButtonTitle>
          <MatchButtonDescription data-sentry-element="MatchButtonDescription" data-sentry-source-file="index.tsx">
            {t('NEXT_VIDEO_CHAT_KEY')}
          </MatchButtonDescription>
        </MatchButtonTextContainer>
      </MatchButtonContainer>
    </Container>;
};
export default DesktopMatchFooter;