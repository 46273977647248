import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Image from 'src/components/Image';
import { DecoEffect } from 'src/types/Deco';
import NewBadgeBase from 'src/components/Effects/NewBadge';
import useEffectButton from './useEffectButton';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import useEvent from 'src/hooks/useEvent';
import { useAtomValue } from 'jotai';
import { eventMatchPageAtom } from 'src/stores/match/atoms';
import { effectGroupMapAtom } from 'src/stores/deco/atom';
import { mouseHoverCss } from 'src/utils/styled';
import LoadingIndicator from 'src/components/LoadingIndicator';
const NewBadge = styled(NewBadgeBase)`
  z-index: 2;
  position: absolute;
  top: 4px;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 4px;

  ${({
  theme
}) => theme.screenSize.tablet} {
    top: 3px;
    ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 5px;
  }
`;
const RoundedEffectButton = styled.button<{
  active: boolean;
  available: boolean;
}>`
  all: unset;
  position: relative;
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
  width: 74px;
  height: 74px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;
  --border-width: ${({
  active,
  available
}) => active && available ? '3px' : '1px'};

  border: ${({
  theme,
  active,
  available
}) => active && available ? `var(--border-width) solid ${theme.color.green500}` : `var(--border-width) solid ${theme.color.gray100__dkGray150}`};

  ${({
  available
}) => !available && css`
      opacity: 40%;
    `}

  ${({
  active,
  available,
  theme
}) => !active && available && mouseHoverCss(`
      border: var(--border-width) solid ${theme.color.green500};
    `)}

  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    border-radius: 50%;
    border: ${({
  theme,
  active
}) => active ? `var(--border-width) solid ${theme.color.green500}` : `var(--border-width) solid ${theme.color.white__dkBlack}`};
    --border-width: ${({
  active
}) => active ? '2px' : '1px'};

    ${NewBadge} {
      ${({
  active,
  theme
}) => active && css`
          top: 2px;
          ${theme.isRtl ? 'left' : 'right'}: 4px;
        `}
    }
  }
`;
const RoundedEffectInner = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${({
  theme
}) => theme.color.gray200__dkGray200};

  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;
const RoundedLoadingDimContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;

  ${({
  theme
}) => theme.screenSize.tablet} {
    border-radius: 50%;
  }
`;
const FilterIconImage = styled(Image)`
  width: 74px;
  height: 74px;
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 72.32px;
    height: 64px;
  }
`;
interface Props {
  effect: DecoEffect;
}
const SelectEffectButton: FC<Props> = ({
  effect
}) => {
  const {
    isLoading,
    isAvailable,
    isActive,
    activateEffect,
    withEffectAvailableCheck
  } = useEffectButton(effect);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const effectGroupMap = useAtomValue(effectGroupMapAtom);
  const pushEvent = useEvent();
  const onClick = withEffectAvailableCheck(() => {
    activateEffect();
    pushEvent({
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.CLICK_DECO_STUDIO_SELECT_ITEM,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: eventMatchPage,
        target: 'deco',
        effect_id: effect.effectId,
        group_id: effectGroupMap.get(effect.effectId)
      }
    });
  });
  return <RoundedEffectButton active={isActive} available={isAvailable} onClick={onClick} data-testid={`Effect-Button-${effect.effectId}`} data-isActive={isActive} data-sentry-element="RoundedEffectButton" data-sentry-component="SelectEffectButton" data-sentry-source-file="SelectEffectButton.tsx">
      {effect.newBadgeEnabled && <NewBadge />}
      <RoundedEffectInner data-sentry-element="RoundedEffectInner" data-sentry-source-file="SelectEffectButton.tsx">
        <FilterIconImage src={effect.thumbnailImageUrl} isBig={false} supportImageSet={false} data-sentry-element="FilterIconImage" data-sentry-source-file="SelectEffectButton.tsx" />
      </RoundedEffectInner>
      {isActive && isLoading && <RoundedLoadingDimContainer data-testid='Effect-Button-Loading'>
          <LoadingIndicator size={24} />
        </RoundedLoadingDimContainer>}
    </RoundedEffectButton>;
};
export default SelectEffectButton;