// 참고: https://www.notion.so/hpcnt/API-ffc2a2c9d876475a86fa52d965e5808f 매치 API 가이드문서
import { InventoryItem } from 'src/types/AzarUser';
import { UserEffectSetting } from 'src/types/Deco';
import {
  MatchConfig,
  MatchFilter,
  MatchFilterSelection,
  MatchOptions,
  MLMatchInferences,
  MLMatchMetrics,
  MLMatchSummary,
  PreviousMatchSummary,
  RandomMatchContext,
} from 'src/types/Match';
import { ReportMatchMethod } from 'src/types/Match';
import { ReportType } from 'src/types/report';
import { createBeaconApiCall, createApiCall } from 'src/utils/api';
import { PeerConnectionClient } from 'src/utils/webrtc';

export const reportMLMatchInferencesAPI =
  createBeaconApiCall<MLMatchInferences>('/machine_learning/report/period');

export const reportMLMatchSummaryAPI = createBeaconApiCall<MLMatchSummary>(
  '/machine_learning/report/summary'
);

export const reportMLMatchMetricsAPI = createBeaconApiCall<MLMatchMetrics>(
  '/machine_learning/v2/report/metrics/match'
);

interface AbortMatchParams {
  matchId: string;
}

/**
 * 서버에서 매칭된 매치의 중단 API
 */
export const abortMatchAPI = createApiCall<
  AbortMatchParams,
  { updatedItems: InventoryItem[] }
>('post', '/api/v1/match/abort');

/**
 * 매칭되기 전 취소 API
 */
export const cancelMatchRandomAPI = createApiCall(
  'post',
  '/api/v1/match/cancel'
);

export const listMatchFiltersAPI = createApiCall<void, MatchFilter[]>(
  'get',
  '/api/v1/match/filters'
);

/**
 * 매치풀 등록 API
 */
export const matchRandomAPI = createApiCall<
  {
    matchOptions: MatchOptions;
    matchEffectIds: string[];
    randomMatchContext: RandomMatchContext;
    matchFilterSelections?: MatchFilterSelection[];
    userEffectSetting?: UserEffectSetting;
    previousMatchSummary?: PreviousMatchSummary;
  },
  MatchConfig
>('post', '/api/v1/match/request');

export interface ReportMatchParams {
  // voiceInfo: any,
  matchId: string;
  fileInfo: null | {
    bucketName: string;
    objectName: string;
  };
  reportType: ReportType;
  reportMethod?: ReportMatchMethod;
}

/**
 * 매치 신고 API
 */
export const ReportMatchAPI = createApiCall<ReportMatchParams>(
  'post',
  '/api/v1/reports/match'
);

export interface CameraInMatchInfo {
  activatedOnStart: boolean;
  activatedOnEnd: boolean;
  statusChangedTimeMs: number[];
}

interface ReportMatchMetricsParams {
  matchUid: string;
  initiator?: boolean;
  iceConnectTimeMs?: number;
  chatDurationMs?: number;
  finisher?: boolean;
  localCandidateType?: string;
  remoteCandidateType?: string;
  firstFrameReceivedTimeMs?: number;
  facialBlurOnWhenLogin?: boolean;
  facePositions?: number[];
  numRecognizedFace?: number;
  cameraInMatchInfo?: CameraInMatchInfo;
}

/**
 * 매치 지표 분석을 위한 보고 API
 */
export const reportMatchMetricsAPI =
  createBeaconApiCall<ReportMatchMetricsParams>(
    '/api/v1/match/metrics/reports'
  );

/**
 * 매치 시작됨을 보고하는 API
 */
export const reportMatchStartAPI = createApiCall<
  {
    matchId: string;
    iceConnectTimeMs?: number;
    acceptTimeMs?: number;
  },
  { updatedItems: InventoryItem[] }
>('post', '/api/v1/match/start');

interface SendTextChatRequest {
  matchId: string;
  message: string;
}
export const sendTextChatAPI = createApiCall<SendTextChatRequest>(
  'post',
  '/api/v1/match/messages'
);

interface SendWebRTCStatInfoV1Params {
  matchId: string;
  stats: ReturnType<PeerConnectionClient['getWebRTCMatchStats']> | EmptyObject;
}

export const sendWebRTCStatInfoV1API =
  createBeaconApiCall<SendWebRTCStatInfoV1Params>('/api/v1/webrtc/reports');
