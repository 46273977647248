import styled from '@emotion/styled';
import RSelect, { GroupBase, Props } from 'react-select';
import { Label } from 'src/components/Input';
import { hoverAndActiveCss } from 'src/utils/styled';
const StyledRSelect = styled(RSelect)<{
  absolute?: boolean;
}>`
  .react-select__indicator {
    transition: transform 0.2s;
    svg {
      fill: ${({
  theme
}) => theme.color.gray700__dkGray700};
    }
    padding: 0;
  }
  .react-select__indicator-separator {
    display: none;
    focus: none;
  }
  .react-select__control--menu-is-open {
    border: 1px solid #18da9e;
    .react-select__indicator {
      transform: rotate(-180deg);
    }
  }
  .react-select__placeholder {
    font-size: 15px;
    color: ${({
  theme
}) => theme.color.gray500__dkGray400};
    margin: 0;
  }
  .react-select__single-value,
  .react-select__option {
    background-color: transparent;
    font-size: 15px;
    color: ${({
  theme
}) => theme.color.white};
    margin: 0;
  }
  .react-select__option--is-focused {
    ${({
  theme
}) => hoverAndActiveCss(`
        color: ${theme.color.white};
      `)}
  }
  .react-select__option--is-focused,
  .react-select__option--is-selected {
    cursor: pointer;
    ${({
  theme
}) => hoverAndActiveCss(`
      background-color: ${theme.color.gray200__dkGray200};
    `)}
  }
  .react-select__option--is-selected {
    & {
      color: ${({
  theme
}) => theme.color.green500};
    }
    ${({
  theme
}) => hoverAndActiveCss(`
        color: ${theme.color.green500};
    `)}
  }
  .react-select__value-container {
    padding: 0;
  }
  .react-select__option,
  .react-select__control {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({
  theme
}) => theme.isRtl ? '12px 16px 12px 8px' : '12px 8px 12px 16px'};
    width: 100%;
    box-sizing: border-box;
  }

  .react-select__control {
    background-color: ${({
  theme
}) => theme.color.gray100__dkGray150};
    border-radius: 8px;
    height: 56px;
    box-shadow: none;
    outline: none;
  }
  .react-select__menu {
    ${({
  absolute
}) => absolute && `
      top: 100%;
      height: 500%;
      position: absolute;
    `}
    box-shadow: none;
    ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: 0;
    border-radius: 0px;
    background-color: transparent;
    z-index: 2;
  }
  .react-select__menu-list {
    ${({
  absolute
}) => absolute && `
      top: 0;
      position: absolute;
    `}
    width: 100%;
    overflow-x: hidden;
    max-height: 100%;
    background-color: ${({
  theme
}) => theme.color.gray100__dkGray150};
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
  }
` as typeof Select;
const Wrap = styled.div<{
  hasLabel: boolean;
}>`
  position: relative;
  ${({
  hasLabel
}) => hasLabel && `
    .react-select__control .react-select__single-value {
      margin-top: 17px;
    }
  `}
`;
type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<Option, IsMulti, Group> & {
  label?: string;
  'data-testid'?: string;
  absolute?: boolean;
};
const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: SelectProps<Option, IsMulti, Group>) => <Wrap className={props.className} data-testid={props['data-testid']} hasLabel={!!props.label} data-sentry-element="Wrap" data-sentry-component="Select" data-sentry-source-file="index.tsx">
    {props.label && <Label isFocus={true}>{props.label}</Label>}
    <StyledRSelect {...props} classNamePrefix='react-select' data-sentry-element="StyledRSelect" data-sentry-source-file="index.tsx" />
  </Wrap>;
export default Select;