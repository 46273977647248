import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { mobileLayoutAtom, sourceStreamAtom, statusAtom, stepAtom } from 'src/stores/match/atoms';
import { MobileLayout, STATUS } from 'src/types/Match';
const Peer = styled.div<{
  status: STATUS;
  step: number;
  isPlayedAnimation: boolean;
  mobileLayout: MobileLayout;
  hasStream: boolean;
}>`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({
  theme
}) => theme.color.black};
  ${({
  theme
}) => theme.screenSize.desktop} {
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
  }
  ${({
  theme,
  status
}) => status === STATUS.MATCHED && `
    background-color: ${theme.color.gray60__dkGray100};
    justify-content: space-between;
  `}
  ${({
  theme
}) => theme.screenSize.tablet} {
    overflow: visible;
    width: 100%;
    transition: height 0.7s cubic-bezier(0, 0.715, 0.32, 1);
    ${({
  mobileLayout,
  status,
  hasStream
}) => {
  if (status === STATUS.INITIAL && hasStream) return 'display: none;';
  if (mobileLayout === 'DEFAULT' || status === STATUS.INITIAL) return 'height: 100%;';
  return `
      height: calc(var(--vh) * 50);
      top: 0;
    `;
}}
  }
  // safari <= 16에서 video에 border-radius 안 먹는 문제 해결
  will-change: transform;
`;
interface IPeerVideoPolygon {
  isPlayedAnimation: boolean;
}
const PeerVideoPolygon: FC<PropsWithChildren<IPeerVideoPolygon>> = props => {
  const status = useAtomValue(statusAtom);
  const mobileLayout = useAtomValue(mobileLayoutAtom);
  const step = useAtomValue(stepAtom);
  const sourceStream = useAtomValue(sourceStreamAtom);
  return <Peer {...props} status={status} step={step} mobileLayout={mobileLayout} hasStream={!!sourceStream} data-sentry-element="Peer" data-sentry-component="PeerVideoPolygon" data-sentry-source-file="PeerVideoPolygon.tsx" />;
};
export default PeerVideoPolygon;