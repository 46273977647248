import { FC } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import ModalLayout, { MobileModalType, ModalContent as ModalContentBase, ModalFooter as ModalFooterBase, ModalHeader as ModalHeaderBase, ModalHeaderTitle } from 'src/components/ModalLayout';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
const Desc = styled.div`
  white-space: pre-line;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  margin-bottom: 16px;
  line-height: 140%;
  font-size: 16px;
`;
const ModalFooter = styled(ModalFooterBase)`
  max-width: 100%;
`;
const ModalContent = styled(ModalContentBase)`
  padding-top: 16px;
`;
const ModalHeader = styled(ModalHeaderBase)`
  ${({
  theme
}) => theme.screenSize.tablet} {
    min-height: auto;
  }
`;
const Modal = styled(ModalLayout)`
  width: 500px;
`;
const LiveOffModal: FC = () => {
  const closeModal = useSetAtom(closeModalAtom);
  const {
    t
  } = useTranslation();
  const handleClick = () => {
    closeModal(ModalType.LIVE_OFF);
  };
  return <Modal mobileType={MobileModalType.BOTTOM_SHEET} isDim data-sentry-element="Modal" data-sentry-component="LiveOffModal" data-sentry-source-file="index.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="index.tsx">
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="index.tsx">
          {t('live_off_SAKWAE_title')}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="index.tsx">
        <Desc data-sentry-element="Desc" data-sentry-source-file="index.tsx">{t('live_off_SAKWAE_desc')}</Desc>
      </ModalContent>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="index.tsx">
        <Button id='live_off_confirm' onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t('live_off_SAKWAE_btn')}
        </Button>
      </ModalFooter>
    </Modal>;
};
export default LiveOffModal;