import { useEffect, useMemo } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import AnimatedTextBase from 'src/components/AnimatedText';
import Gap from 'src/components/Gap';
import MatchStartButtonBase from 'src/components/Match/Matching/MatchStartButton';
import { FilterIcon } from 'src/components/Match/Preference/Button';
import { GenderButtonBase, GenderIconBase } from 'src/components/Match/Preference/Gender';
import { LocationButtonBase, LocationIconBase } from 'src/components/Match/Preference/Location';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useIsOpenModal from 'src/hooks/useIsOpenModal';
import useLogin from 'src/hooks/useLogin';
import useStartMatch from 'src/hooks/useStartMatch';
import { getMatchFiltersAtom, sourceStreamAtom, stepAtom } from 'src/stores/match/atoms';
import { ModalType } from 'src/types/Modal';
import { hexToRgb } from 'src/utils/common';
import { IntroButtonBase, IntroIconBase } from './style';
import { mouseHoverCss, touchActiveCss } from 'src/utils/styled';
import { getMLConfigAtom, prepareAntmanAtom } from 'src/stores/ml/atoms';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER } from 'src/types/Event';
const ButtonWrap = styled.div`
  box-sizing: content-box;
  flex-direction: row;
  display: flex;
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: calc(var(--100vw) - 48px);
    flex-direction: column;
  }
`;
const IntroButtonBaseMobile = css`
  height: 54px;
  font-size: 16px;
`;
const createButtonBase = ({
  theme
}: {
  theme: Theme;
}) => css`
  ${IntroButtonBase({
  theme
})}
  flex: 0.5;
  max-width: 50%;
  margin-bottom: 16px;
  background-color: ${theme.color.gray100__dkGray150};
  font-weight: 700;
  ${FilterIcon} {
    fill: ${theme.color.gray900__dkGray970};
  }

  ${theme.screenSize.tablet} {
    ${IntroButtonBaseMobile}
    flex: 0.5;
    margin-bottom: 16px;
    background-color: rgba(${hexToRgb(theme.color.black)}, 0.6);
  }
  ${touchActiveCss(`
    background-color: rgba(${hexToRgb(theme.color.white)}, 0.3);
  `)}

  ${mouseHoverCss(`
    background-color: ${theme.color.gray60__dkGray100};
    ${FilterIcon} {
      fill: ${theme.color.gray700__dkGray700};
    }
  `)}
`;
const IntroGenderButton = styled(GenderButtonBase)`
  ${createButtonBase}
`;
const IntroLocationButton = styled(LocationButtonBase)`
  ${createButtonBase}
`;
const ButtonWrapRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({
  theme
}) => theme.screenSize.desktop} {
    min-width: 50%;
  }
`;
const ButtonWrapGap = styled(Gap)`
  flex-shrink: 0;
`;
const MobileIntroIconBase = css`
  width: 20px;
  height: 20px;
`;
const createIconBase = ({
  theme
}: {
  theme: Theme;
}) => css`
  ${IntroIconBase}
  ${theme.screenSize.tablet} {
    ${MobileIntroIconBase}
  }
`;
const IntroGenderIcon = styled(GenderIconBase)`
  ${createIconBase}
`;
const IntroLocationIcon = styled(LocationIconBase)`
  ${createIconBase}
`;
const disappear = keyframes`
  from {
    visibility: visible;
    z-index: 1;
  }
  to {
    visibility: hidden;
    z-index: 0;
  }
`;
const IntroWrap = styled.div<{
  isShow: boolean;
}>`
  max-height: -webkit-fill-available;
  position: absolute;
  ${({
  isShow
}) => !isShow && css`
      animation: ${disappear} 2s forwards;
    `}
  z-index: 2;
  height: 100%;
  width: calc(50% - 24px);
  top: 0;
  ${({
  theme
}) => (theme.isRtl ? 'right:' : 'left:') + '16px'};
  display: flex;
  justify-content: center;
  transition: z-index 1s;
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 100%;
    left: 0;
    right: 0;
  }
`;
const IntroWrapInner = styled.div<{
  hasStream: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: visible;
  padding: 0 16px 16px;
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 100%;
    justify-content: flex-end;
    padding: 0 24px 89px;
    padding-bottom: ${({
  hasStream
}) => hasStream ? '59px' : '80px'};
  }
`;
const AnimatedText = styled(AnimatedTextBase)`
  width: 100%;
  display: flex;
  > div,
  > div > div {
    width: inherit;
    height: inherit;
  }
`;
const MatchStartButton = styled(MatchStartButtonBase)`
  ${({
  theme
}) => theme.screenSize.desktop} {
    flex: 1;
  }
`;
const MatchIntro = () => {
  const step = useAtomValue(stepAtom);
  const {
    isLogin
  } = useLogin();
  const getMatchFilters = useSetAtom(getMatchFiltersAtom);
  const prepareAntman = useSetAtom(prepareAntmanAtom);
  const getMLConfig = useSetAtom(getMLConfigAtom);
  const startMatch = useStartMatch();
  useEffect(() => {
    if (isLogin) {
      getMatchFilters();
      getMLConfig().then(() => {
        prepareAntman();
      });
    }
  }, [getMatchFilters, prepareAntman, isLogin, getMLConfig]);
  const sourceStream = useAtomValue(sourceStreamAtom);
  const hasStream = useMemo(() => !!sourceStream, [sourceStream]);
  const isOpenDecoModal = useIsOpenModal(ModalType.DECO_STUDIO);
  const media = useCssMediaDevice();
  const isShow = step !== 2 && (!isOpenDecoModal || media === 'desktop');
  return <IntroWrap isShow={isShow} data-sentry-element="IntroWrap" data-sentry-component="MatchIntro" data-sentry-source-file="index.tsx">
      <IntroWrapInner hasStream={hasStream} data-sentry-element="IntroWrapInner" data-sentry-source-file="index.tsx">
        <AnimatedText isShow={step === 1} data-sentry-element="AnimatedText" data-sentry-source-file="index.tsx">
          <ButtonWrap data-testid='match-intro-buttons-container' data-sentry-element="ButtonWrap" data-sentry-source-file="index.tsx">
            <ButtonWrapRow data-sentry-element="ButtonWrapRow" data-sentry-source-file="index.tsx">
              <IntroGenderButton onSave={startMatch} GenderIcon={IntroGenderIcon} landFrom='MIRROR' data-sentry-element="IntroGenderButton" data-sentry-source-file="index.tsx" />
              <ButtonWrapGap width={10} tabletWidth={16} mobileWidth={16} data-sentry-element="ButtonWrapGap" data-sentry-source-file="index.tsx" />
              <IntroLocationButton onSave={startMatch} LocationIcon={<IntroLocationIcon />} landFrom='MIRROR' data-sentry-element="IntroLocationButton" data-sentry-source-file="index.tsx" />
            </ButtonWrapRow>
            <Gap width={10} tabletWidth={0} mobileWidth={0} data-sentry-element="Gap" data-sentry-source-file="index.tsx" />
            <MatchStartButton data-testid='match-intro-start' trigger={EVENT_LOGIN_MODAL_VISITOR_TRIGGER.MATCH_START_BUTTON_MIRROR} executeOnLogin data-sentry-element="MatchStartButton" data-sentry-source-file="index.tsx" />
          </ButtonWrap>
        </AnimatedText>
      </IntroWrapInner>
    </IntroWrap>;
};
export default MatchIntro;