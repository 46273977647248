import { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { ModalFooter as ModalFooterBase } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { featureSettingParamsFromOptionalConsentsAtom, nextStepAtom } from 'src/stores/register/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import IcCheckCircleBase from 'src/assets/images/icCheckCircle.svg';
import IcLogoBase from 'src/assets/images/imgAzarLogoText.svg';
import { Wrap as WrapBase } from 'src/components/LoginModal/styles';
import { Consent } from 'src/stores/register/types';
import { css } from '@emotion/react';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { setTargetBlankToLinks } from 'src/utils/html';
import { useGetConsents } from 'src/hooks/useGetConsents';
const Wrap = styled(WrapBase)`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;
const LogoContainer = styled.div`
  padding: 16px 8px 32px 8px;
`;
const IcLogo = styled(IcLogoBase)`
  width: 144px;
  height: 36px;

  * {
    fill: ${({
  theme
}) => theme.color.black__dkWhite};
  }
`;
const NoConsentText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: ${({
  theme
}) => theme.color.black__dkWhite};

  ${({
  theme
}) => theme.screenSize.mobile} {
    font-size: 20px;
    line-height: 26px;
  }
`;
const ConsentsWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    gap: 16px;
  }

  a {
    color: inherit;
  }
`;
const IcCheckCircle = styled(IcCheckCircleBase)<{
  isChecked: boolean;
}>`
  width: 24px;
  height: 24px;
`;
const ConsentWrapCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
`;
const ConsentWrap = styled.div`
  ${ConsentWrapCss}
  background-color: ${({
  theme
}) => theme.color.gray100__dkGray150};
`;
const ConsentContent = styled.div`
  flex-grow: 1;
  padding: 18px 20px;
  ${({
  theme
}) => theme.isRtl ? 'padding-left' : 'padding-right'}: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ConsentTitle = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
const ConsentDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
`;
const CheckIconContainerCss = css`
  padding: 0 16px;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: inherit;
`;
const ConsentLabel = styled.label`
  ${CheckIconContainerCss};
`;
const ConsentCheckIcon = styled(IcCheckCircle)`
  fill: ${({
  theme
}) => theme.color.gray300__dkGray250};
  ${({
  isChecked,
  theme
}) => isChecked && `
    fill: ${theme.color.black__dkWhite};
  `}
`;
const CheckAllIconContainer = styled.div`
  ${CheckIconContainerCss};
`;
const CheckAllIcon = styled(IcCheckCircle)`
  fill: ${({
  theme
}) => theme.color.gray700__dkGray700};
  ${({
  isChecked,
  theme
}) => isChecked && `
    fill: ${theme.color.gray60__dkGray100};
  `}
`;
const ConsentAllWrap = styled.button`
  all: unset;
  ${ConsentWrapCss};
  background-color: ${({
  theme
}) => theme.color.gray800__dkGray900};

  ${ConsentContent} {
    overflow: hidden;
  }

  ${ConsentTitle} {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: ${({
  theme
}) => theme.color.gray30__dkGray50};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const Guideline = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  word-break: keep-all;
  margin-top: auto;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};

  ${({
  theme
}) => theme.screenSize.mobile} {
    font-size: 12px;
    line-height: 18px;
  }

  a {
    color: ${({
  theme
}) => theme.color.gray900__dkGray970};
  }
`;
const ModalFooter = styled(ModalFooterBase)`
  padding-top: 0;
  gap: 16px;
`;

/**
 * Key: consent id
 * Value: 해당 약관 동의 여부
 */
type ConsentCheckedMap = Record<Consent['consentId'], boolean>;

/**
 * 약관 동의 절차
 */
export const ConsentsA: FC = () => {
  const {
    t
  } = useTranslation();
  const [requiredConsentsCheckedMap, setRequiredConsentsCheckedMap] = useState<ConsentCheckedMap | null>(null);
  const [optionalConsentsCheckedMap, setOptionalConsentsCheckedMap] = useState<ConsentCheckedMap | null>(null);
  // state이 모두 갱신된 이후에 다음 단계로 넘어가는 것이 목적
  const [shouldMoveNextStep, setShouldMoveNextStep] = useState(false);
  const tryLoginType = useAtomValue(tryLoginTypeAtom);
  const moveNextStep = useSetAtom(nextStepAtom);
  const setOptionalFeatureSettingParams = useSetAtom(featureSettingParamsFromOptionalConsentsAtom);
  const pushEvent = useEvent();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useCssMediaDevice() === 'mobile';
  const {
    isSuccess,
    data
  } = useGetConsents();
  const requiredConsents = useMemo(() => data?.data?.result ? [...data.data.result.consentsRequired, ...data.data.result.tspConsents] : [], [data?.data?.result]);
  const optionalConsents = useMemo(() => data?.data?.result?.optionalConsents ?? [], [data?.data?.result]);
  const requiredConsentsWithCheckbox = useMemo(() => requiredConsents.filter(requiredConsent => requiredConsent.requiresInput), [requiredConsents]);
  const optionalConsentsWithCheckbox = useMemo(() => optionalConsents.filter(optionalConsent => optionalConsent.requiresInput), [optionalConsents]);
  useEffect(() => {
    if (!data?.data?.result) return;
    const initializeCheckedMap = (consents: Consent[]) => {
      return consents.reduce((acc, consent) => {
        return {
          ...acc,
          // 체크박스 미제공의 경우 이미 동의된 것으로 간주
          [consent.consentId]: !consent.requiresInput
        };
      }, {});
    };
    setRequiredConsentsCheckedMap(initializeCheckedMap(requiredConsents));
    setOptionalConsentsCheckedMap(initializeCheckedMap(optionalConsents));
  }, [data?.data?.result, setOptionalConsentsCheckedMap, requiredConsents, optionalConsents]);
  const isRequiredAllChecked = useMemo(() => {
    if (!isSuccess || requiredConsentsCheckedMap === null) return false;
    return Object.values(requiredConsentsCheckedMap).every(isChecked => isChecked);
  }, [requiredConsentsCheckedMap, isSuccess]);
  const isAllChecked = useMemo(() => {
    return isRequiredAllChecked && optionalConsentsCheckedMap !== null && Object.values(optionalConsentsCheckedMap).every(isChecked => isChecked);
  }, [isRequiredAllChecked, optionalConsentsCheckedMap]);
  const handleClickNext = () => {
    setShouldMoveNextStep(true);
  };
  const toggleAllChecked = () => {
    if (isLoading) return;
    const toBeChecked = !isAllChecked;
    const fillWithToBeChecked = (prev: ConsentCheckedMap | null) => {
      if (prev === null) return null;
      return Object.fromEntries(Object.keys(prev).map(key => [key, toBeChecked]));
    };
    setOptionalConsentsCheckedMap(fillWithToBeChecked);
    setRequiredConsentsCheckedMap(fillWithToBeChecked);
    if (toBeChecked) {
      // 전체동의 눌러서 모두 동의시 자동으로 다음 단계 진행
      setIsLoading(true);
      setTimeout(() => {
        handleClickNext();
      }, 200);
    }
  };
  useEffect(() => {
    if (!shouldMoveNextStep) return;
    if (optionalConsents.length > 0 && optionalConsentsCheckedMap) {
      const featureSettingParamsFromOptionalConsents = optionalConsents.map(optionalConsent => {
        return {
          type: optionalConsent.type,
          value: optionalConsentsCheckedMap[optionalConsent.consentId]
        };
      });
      setOptionalFeatureSettingParams(featureSettingParamsFromOptionalConsents);
    } else {
      setOptionalFeatureSettingParams(null);
    }
    moveNextStep();
  }, [shouldMoveNextStep, optionalConsents, optionalConsentsCheckedMap, setOptionalFeatureSettingParams, moveNextStep]);
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_TERMS,
      eventParams: {
        loginType: tryLoginType
      }
    });
  }, [pushEvent, tryLoginType]);
  const renderConsentItem = (consent: Consent, isRequired: boolean) => {
    const {
      consentId,
      agreementMessage,
      description
    } = consent;
    const checkedMap = isRequired ? requiredConsentsCheckedMap : optionalConsentsCheckedMap;
    if (checkedMap === null) return null;
    const isChecked = checkedMap[consentId];
    const toggleChecked = () => {
      if (isLoading) return;
      const updateCheckedState = isRequired ? setRequiredConsentsCheckedMap : setOptionalConsentsCheckedMap;
      updateCheckedState(prev => {
        if (prev === null) return null;
        return {
          ...prev,
          [consentId]: !prev[consentId]
        };
      });
    };
    return <ConsentWrap key={consentId} data-sentry-element="ConsentWrap" data-sentry-component="renderConsentItem" data-sentry-source-file="ConsentsA.tsx">
        <ConsentContent data-sentry-element="ConsentContent" data-sentry-source-file="ConsentsA.tsx">
          <ConsentTitle dangerouslySetInnerHTML={{
          __html: setTargetBlankToLinks(agreementMessage)
        }} data-sentry-element="ConsentTitle" data-sentry-source-file="ConsentsA.tsx" />
          {description && <ConsentDescription dangerouslySetInnerHTML={{
          __html: setTargetBlankToLinks(description)
        }} />}
        </ConsentContent>
        <ConsentLabel htmlFor={consentId} data-sentry-element="ConsentLabel" data-sentry-source-file="ConsentsA.tsx">
          <input type='checkbox' id={consentId} onChange={toggleChecked} hidden />
          <ConsentCheckIcon isChecked={isChecked} data-sentry-element="ConsentCheckIcon" data-sentry-source-file="ConsentsA.tsx" />
        </ConsentLabel>
      </ConsentWrap>;
  };
  if (!data?.data?.result) return null;
  const {
    acceptAllEnabled,
    guidelines
  } = data.data.result;
  const hasNoConsents = requiredConsentsWithCheckbox.length === 0 && optionalConsentsWithCheckbox.length === 0;
  const consentToggleAll = acceptAllEnabled && !hasNoConsents ? <ConsentAllWrap onClick={toggleAllChecked} data-testid='TermAgreeStep-button-checkAll'>
        <ConsentContent>
          <ConsentTitle>{t('SIGNUP_TERMS_ACCEPT')}</ConsentTitle>
        </ConsentContent>
        <CheckAllIconContainer>
          <CheckAllIcon isChecked={isAllChecked} />
        </CheckAllIconContainer>
      </ConsentAllWrap> : null;
  return <>
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="ConsentsA.tsx">
        {!acceptAllEnabled && <LogoContainer>
            <IcLogo />
          </LogoContainer>}
        {hasNoConsents && <NoConsentText>{t('tos_guide_empty')}</NoConsentText>}
        <ConsentsWrap data-testid='LoginModal-TermAgreeStep' data-sentry-element="ConsentsWrap" data-sentry-source-file="ConsentsA.tsx">
          {!isMobile && consentToggleAll}
          {requiredConsentsWithCheckbox.map(requiredConsent => renderConsentItem(requiredConsent, true))}
          {optionalConsentsWithCheckbox.map(optionalConsent => renderConsentItem(optionalConsent, false))}
          {!!guidelines?.length && <Guideline dangerouslySetInnerHTML={{
          __html: guidelines.map(guideline => setTargetBlankToLinks(guideline.content)).join(' ')
        }} />}
        </ConsentsWrap>
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="ConsentsA.tsx">
        {isMobile && consentToggleAll}
        <Button disabled={!isRequiredAllChecked} loading={isLoading} onClick={handleClickNext} data-testid='TermAgreeStep-button-next' id='SIGNUP__ACCEPT__TERM_AGREE_STEP' data-sentry-element="Button" data-sentry-source-file="ConsentsA.tsx">
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>;
};