import { FC } from 'react';
import Chat from 'src/components/Chat';
import { Chat as ChattingProps } from 'src/types/Chat';
const Chatting: FC<ChattingProps> = chat => {
  switch (chat.type) {
    case 'azar_message':
      return <Chat {...chat} />;
    default:
      return null;
  }
};
export default Chatting;