import { v4 } from 'uuid';
import * as Sentry from '@sentry/nextjs';

import store from 'src/stores';
import { deviceIdAtom } from 'src/stores/user/atom';

const getDeviceId = () => {
  const deviceId = store.get(deviceIdAtom);
  if (deviceId) {
    Sentry.setTag('deviceId', deviceId);
    return deviceId;
  }
  const newUuid = v4();
  store.set(deviceIdAtom, newUuid);
  Sentry.setTag('deviceId', newUuid);
  return newUuid;
};

export default getDeviceId;
