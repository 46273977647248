import { FC, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { ModalHeader, ModalFooter as ModalFooterBase } from 'src/components/ModalLayout';
import { AuthErrorDesc, AuthErrorMain as AuthErrorMainBase, AuthErrorTitle, AuthErrorModalLayout } from 'src/components/AuthErrorModal/style';
import { useAtom, useSetAtom } from 'jotai';
import { isDeviceAgeRestrictedAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { useRouter } from 'next/router';
import { ModalType } from 'src/types/Modal';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import Image from 'src/components/Image';
const ModalFooter = styled(ModalFooterBase)`
  width: 100%;
`;
const AuthErrorMain = styled(AuthErrorMainBase)`
  min-height: calc(516px - 88px - 58px);
  max-height: calc(100% - 58px);
  justify-content: flex-start;
  padding-top: 32px;
`;
const AgeImg = styled(Image)`
  width: 160px;
  height: 160px;
  margin-bottom: 20px;
`;
const AgeRestrictedModal: FC = () => {
  const {
    t
  } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const [isDeviceAgeRestricted, setIsDeviceAgeRestricted] = useAtom(isDeviceAgeRestrictedAtom);
  const router = useRouter();
  const pushEvent = useEvent();
  const tab = useMemo(() => {
    if (router.pathname === '/' || router.pathname === '/match') return 'mirror';
    if (router.pathname.includes('live')) return 'live';
    if (router.pathname === '/about') return 'about';
    if (router.pathname === '/history') return 'history';
    if (router.pathname.includes('blog')) return 'blog';
    return router.pathname;
  }, [router.pathname]);
  const onSubmit = () => {
    closeModal(ModalType.AGE_RESTRICTED);
    router.push('/');
  };
  useEffect(() => {
    // 최초로 영구제한 걸렸을 때에만 로그 전송
    if (isDeviceAgeRestricted) return;
    pushEvent({
      eventType: EVENT_TYPE.REPORT,
      eventName: EVENT_NAME.ANYWHERE__REPORT_UNDERAGE_BLOCK,
      eventParams: {
        action_category: 'report',
        tab,
        page: 'main',
        target: 'underage_block',
        reason: tab === 'live' ? 'live' : 'registration'
      }
    });
  }, [isDeviceAgeRestricted, pushEvent, tab]);
  useEffect(() => {
    setIsDeviceAgeRestricted(true);
  }, [setIsDeviceAgeRestricted]);
  return <AuthErrorModalLayout onClose={onSubmit} isDim data-sentry-element="AuthErrorModalLayout" data-sentry-component="AgeRestrictedModal" data-sentry-source-file="index.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="index.tsx" />
      <AuthErrorMain data-sentry-element="AuthErrorMain" data-sentry-source-file="index.tsx">
        <AgeImg src='/images/register/age-restricted.png' alt='Age Restricted' data-sentry-element="AgeImg" data-sentry-source-file="index.tsx" />
        <AuthErrorTitle data-sentry-element="AuthErrorTitle" data-sentry-source-file="index.tsx">{t('age_restricted_title')}</AuthErrorTitle>
        <AuthErrorDesc data-sentry-element="AuthErrorDesc" data-sentry-source-file="index.tsx">{t('age_restricted_desc_no_param')}</AuthErrorDesc>
      </AuthErrorMain>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="index.tsx">
        <Button onClick={onSubmit} id='COMMON__OK' data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t('COMMON__OK')}
        </Button>
      </ModalFooter>
    </AuthErrorModalLayout>;
};
export default AgeRestrictedModal;