import { useSetAtom } from 'jotai';
import { FC } from 'react';
import EmailAddressRegisterStep from './EmailAddressRegisterStep';
import Template from './Template';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import { nextStepAtom, resetRegisterAtom } from 'src/stores/register/atoms';
import { addStepsOnPhoneNumberLoginStepsAtom, isRemoveOldDeviceUserDetailsAtom, PhoneNumberRegisterSteps } from 'src/stores/phoneNumberLogin/atoms';
import { useTranslation } from 'react-i18next';
const NotExistEmailStep: FC = () => {
  const helpCenterUrl = useHelpCenterUrl();
  const resetRegister = useSetAtom(resetRegisterAtom);
  const addSteps = useSetAtom(addStepsOnPhoneNumberLoginStepsAtom);
  const goNextStep = useSetAtom(nextStepAtom);
  const setRemoveOldDeviceUserDetails = useSetAtom(isRemoveOldDeviceUserDetailsAtom);

  // 기존 PhoneNumber 계정 삭제하고, 동일 PhoneNumber로 새 계정 생성
  const handleMainClick = () => {
    setRemoveOldDeviceUserDetails(true);
    addSteps(EmailAddressRegisterStep, ...PhoneNumberRegisterSteps);
    goNextStep();
  };

  // PhoneNumber 말고 다른 방법으로 로그인
  const handleSubClick = () => {
    resetRegister();
  };
  const {
    t
  } = useTranslation();
  return <Template eventId='signup_phone_not_verified_title' title={t('signup_phone_not_verified_title')} description={t('signup_phone_not_verified_desc')} link={helpCenterUrl} mainButtonProps={{
    onClick: handleMainClick,
    text: t('signup_phone_btn_reg')
  }} subButtonProps={{
    onClick: handleSubClick,
    text: t('signup_phone_btn_other_methods')
  }} linkText={t('signup_phone_btn_helpcenter')} data-sentry-element="Template" data-sentry-component="NotExistEmailStep" data-sentry-source-file="NotExistEmailStep.tsx" />;
};
export default NotExistEmailStep;