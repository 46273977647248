import styled from '@emotion/styled';
import { FC, PropsWithChildren, useCallback } from 'react';
import useIntersection from 'src/hooks/useIntersection';
const Base = styled.div<Pick<AnimatedTextProps, 'isFade' | 'isSlide' | 'direction'> & {
  preventBreak?: boolean;
}>`
  position: relative;
  overflow: ${({
  isFade,
  isSlide
}) => isSlide ? 'visible' : isFade ? 'visible' : 'hidden'};
  display: inline-block;
  white-space: pre-line;
  ${({
  preventBreak
}) => !preventBreak && `
    word-break: keep-all;
  `}
`;
const Word = styled.div<Pick<AnimatedTextProps, 'delay' | 'isSlide' | 'isGrow' | 'isFade' | 'isShow' | 'direction'>>`
  display: inline-block;
  transition-property: transform, opacity;
  transition-timing-function: ${({
  isFade
}) => isFade ? 'cubic-bezier(0.19, 1, 0.22, 1), cubic-bezier(0.19, 1, 0.22, 1)' : 'cubic-bezier(.075,.82,.165,1)'};
  ${({
  isSlide,
  direction
}) => {
  if (!isSlide || !direction) {
    return;
  }
  switch (direction) {
    case 'left':
      {
        return 'transform: translateX(50px);';
      }
    case 'right':
      {
        return 'transform: translateX(-50px);';
      }
    case 'down':
      {
        return 'transform: translateY(-50px);';
      }
    case 'up':
      {
        return 'transform: translateY(50px);';
      }
  }
}}
  ${({
  isGrow
}) => isGrow && `
    transform: scale(0.7);
  `}
  opacity: ${({
  isFade
}) => isFade ? 0 : 1};
  transition-duration: ${({
  isFade,
  isSlide
}) => isFade && isSlide ? '2s, 1s' : isFade ? '1.3s' : '.8s'};

  &.show {
    ${({
  delay,
  isShow
}) => isShow && `
      transition-delay: ${delay}ms;
    `}
    ${({
  isShow
}) => isShow && `
      transform: translateY(0) scale(1);
      opacity: 1;
    `}
  }
`;
export interface AnimatedTextProps extends PropsWithChildren {
  text?: string;
  isSlide?: boolean;
  isFade?: boolean;
  isGrow?: boolean;
  isShow?: boolean;
  className?: HTMLDivElement['className'];
  direction?: 'up' | 'down' | 'left' | 'right';
  unit?: string;
  delay?: number;
}
const AnimatedText: FC<AnimatedTextProps> = ({
  text,
  isSlide = true,
  isFade = true,
  isGrow = false,
  direction = 'down',
  className,
  unit = 'line',
  isShow = true,
  delay = 0,
  children
}) => {
  // const { language } = useContext(LanguageContext);
  // const preventBreak = !!language && [SupportLanguage.ZH_CN, SupportLanguage.JA].includes(language);
  const baseProps = {
    isSlide,
    isFade,
    direction
    // preventBreak,
  };
  const wordProps = {
    delay,
    className: `animated-word${isSlide ? ' slide' : ''}`,
    isFade,
    isSlide,
    isGrow,
    isShow,
    direction
  };
  const animate = useCallback((element: Element) => {
    Array.from(element.getElementsByClassName('animated-word')).forEach(el => {
      el.classList.add('show');
    });
  }, []);
  const handleIntersect = useCallback<IntersectionObserverCallback>(([entry], observer) => {
    if (entry.isIntersecting && isShow) {
      animate(entry.target);
      observer.unobserve(entry.target);
    }
  }, [animate, isShow]);
  const {
    observerRef
  } = useIntersection(handleIntersect);
  return <div className={className} ref={observerRef} data-sentry-component="AnimatedText" data-sentry-source-file="index.tsx">
      {text ? text.split('\\n').map((line, i) =>
    // eslint-disable-next-line react/no-array-index-key
    <div key={`animated-text-${line}-${i}`}>
            {unit === 'word' ? line.split(' ').map((word, j) => {
        wordProps.delay += 100; // TODO: IMMUTABLE
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Base key={`animated-text-${line}-${i}-${word}-${j}`} {...baseProps}>
                    <Word {...wordProps}>{`${word} `}</Word>
                  </Base>
        );
      }) : <Base {...baseProps}>
                <Word {...wordProps}>{`${line}`}</Word>
              </Base>}
          </div>) : <Base {...baseProps}>
          <Word {...wordProps}>{children}</Word>
        </Base>}
    </div>;
};
export default AnimatedText;