import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import CoachMarks from 'src/components/Match/Matching/CoachMarks';
import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
import { RedDot, ToolButton, ToolButtonIcon } from 'src/components/Match/Matching/Toolbar/styles';
import useCallbackWithCheckLogin from 'src/hooks/useCallbackWithCheckLogin';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import useIsOpenModal from 'src/hooks/useIsOpenModal';
import { autoActivateGestureEffectsAtom, handleUnsupportedDecoAccessAtom, isDecoSupportedAtom, isShowDecoRedDotAtom, isShowToolbarAtom } from 'src/stores/deco/atom';
import { headerAtom } from 'src/stores/header/atoms';
import { eventMatchPageAtom, grantVideoAtom, statusAtom } from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { EffectsModal } from 'src/components/Effects';
import useEffectCoachMark, { EffectCoachMarkType } from 'src/hooks/useEffectCoachmark';
import getDeviceInfo from 'src/utils/device/info';
import useEventLoginModalVisitorTrigger from 'src/hooks/useEventLoginModalVisitorTrigger';
const Wrap = styled.div`
  position: relative;
`;
const COACH_MARK_INFO: Record<EffectCoachMarkType, {
  image: string;
  descriptionTranslationKey: string;
}> = {
  [EffectCoachMarkType.Default]: {
    image: '/images/deco/coachMark.webp',
    descriptionTranslationKey: 'deco_studio_coach_mark'
  },
  [EffectCoachMarkType.NewEffect]: {
    /**
     * @TODO 실제 이미지 나오면 적용 필요
     */
    image: '/images/deco/gestureEffectCoachMark.webp',
    descriptionTranslationKey: 'deco_studio_coach_mark_gesture'
  }
};
const DecoFilterButton: FC = () => {
  const showModal = useSetAtom(showModalAtom);
  const isOpenDecoModal = useIsOpenModal(ModalType.DECO_STUDIO);
  const setIsShowToolbar = useSetAtom(isShowToolbarAtom);
  const setIsShowHeader = useSetAtom(headerAtom);
  const media = useCssMediaDevice();
  const isShowRedDot = useAtomValue(isShowDecoRedDotAtom);
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const status = useAtomValue(statusAtom);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const handleUnsupportedDecoAccess = useSetAtom(handleUnsupportedDecoAccessAtom);
  const autoActivateGestureEffects = useSetAtom(autoActivateGestureEffectsAtom);
  const pushEvent = useEvent();
  const {
    t
  } = useTranslation();
  const {
    isMobile,
    isWebKit
  } = getDeviceInfo();
  const {
    coachMarkType,
    closeCoachMark
  } = useEffectCoachMark();
  const setEventLoginModalVisitorTrigger = useEventLoginModalVisitorTrigger();
  useEffect(() => {
    if (media !== 'desktop') {
      setIsShowToolbar(!isOpenDecoModal && status === STATUS.INITIAL);
      setIsShowHeader(!isOpenDecoModal && status === STATUS.INITIAL);
    } else {
      setIsShowToolbar(true);
    }
  }, [isOpenDecoModal, setIsShowToolbar, media, setIsShowHeader, status]);
  const grantVideo = useSetAtom(grantVideoAtom);
  const showDecoModal = () => {
    showModal({
      key: ModalType.DECO_STUDIO,
      component: () => <EffectsModal />
    });
  };
  const handleClickEffect = useCallbackWithCheckLogin(async () => {
    if (!isDecoSupported) {
      handleUnsupportedDecoAccess();
      return;
    }
    if (isWebKit && isMobile) {
      autoActivateGestureEffects();
    }
    await grantVideo({
      onSuccess: showDecoModal,
      errorModal: PermissionErrorFullModal
    });
    pushEvent({
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.OPEN_DECO_STUDIO,
      eventParams: {
        action_category: 'open',
        tab: 'mirror',
        page: eventMatchPage,
        target: 'deco'
      }
    });
  });
  const onClick = () => {
    setEventLoginModalVisitorTrigger(EVENT_LOGIN_MODAL_VISITOR_TRIGGER.DECO_FILTER_BUTTON);
    handleClickEffect();
  };
  return (
    // 사용자 유도 관련 UI(eg. 레드닷, 코치마크)는 데코 이용 불가시 미노출
    <Wrap data-testid='Effect-Studio-Button' data-sentry-element="Wrap" data-sentry-component="DecoFilterButton" data-sentry-source-file="DecoFilterButton.tsx">
      <ToolButton isAvailable={isDecoSupported} onClick={onClick} data-sentry-element="ToolButton" data-sentry-source-file="DecoFilterButton.tsx">
        {isShowRedDot && isDecoSupported && <RedDot />}
        <ToolButtonIcon alt='Deco filter' src='/images/icons/icEffectFill.svg' data-sentry-element="ToolButtonIcon" data-sentry-source-file="DecoFilterButton.tsx" />
      </ToolButton>
      {coachMarkType !== null && <CoachMarks image={COACH_MARK_INFO[coachMarkType].image} description={t(COACH_MARK_INFO[coachMarkType].descriptionTranslationKey)} close={closeCoachMark} />}
    </Wrap>
  );
};
export default DecoFilterButton;