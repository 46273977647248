import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TimerStatus } from 'src/types/timer';
import { millisecondsToHHMMSS } from 'src/utils/date';

type TConfig = {
  autostart: boolean;
  endTime: number; // seconds 단위
  initialTime: number;
  interval: number;
  onTimeOver?: () => void;
  onTimeUpdate?: (time: number) => void;
  increment: boolean;
};

export default function useTimer({
  autostart = false,
  endTime = 0,
  initialTime = 0,
  interval = 1000,
  onTimeOver,
  onTimeUpdate,
  increment = true,
}: Partial<TConfig> = {}) {
  const intervalId = useRef<NodeJS.Timeout>();
  const [timer, setTimer] = useState(
    initialTime === 0 && endTime > 0 && !increment ? endTime : initialTime
  );
  const [status, setStatus] = useState(TimerStatus.INIT);

  const clearIntervalId = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  };

  const stop = useCallback(() => {
    clearIntervalId();
    setStatus(TimerStatus.STOP);
    setTimer(endTime);
  }, [setStatus, setTimer, endTime]);

  const pause = useCallback(() => {
    setStatus(TimerStatus.PAUSE);
    clearIntervalId();
  }, [setStatus]);

  const reset = useCallback(() => {
    setStatus(TimerStatus.RESET);
    clearIntervalId();

    setTimeout(() => {
      setTimer(increment ? initialTime : endTime);
      setStatus(TimerStatus.START);
    }, 0);
  }, [increment, initialTime, endTime, setTimer, setStatus]);

  useEffect(() => {
    if (
      (autostart && status === TimerStatus.INIT) ||
      (!autostart && status === TimerStatus.START)
    ) {
      setStatus(TimerStatus.START);
    }
  }, [autostart, status]);

  useEffect(() => {
    const tick = () => {
      intervalId.current = setInterval(() => {
        const next = increment ? timer + 1 : timer - 1;
        setTimer(next);

        if (onTimeUpdate) {
          onTimeUpdate(next);
        }
      }, interval);
    };

    if (status === TimerStatus.START) {
      tick();
    }

    return () => clearIntervalId();
  }, [status, intervalId, timer, setTimer, onTimeUpdate, increment, interval]);

  useEffect(() => {
    const timeOver = () => {
      setStatus(TimerStatus.TIMEOVER);
      clearIntervalId();

      if (onTimeOver) {
        onTimeOver();
      }
    };

    if (
      status === TimerStatus.START &&
      ((increment && timer === endTime) ||
        (!increment && timer === initialTime))
    ) {
      timeOver();
    }
  }, [timer, increment, endTime, initialTime, status, onTimeOver]);

  const time = useMemo(() => {
    const formattedTime = millisecondsToHHMMSS(timer * 1000);
    const v = formattedTime.split(':');

    return {
      hour: v[0],
      minute: v[1],
      second: v[2],
    };
  }, [timer]);

  const start = useCallback(() => {
    setStatus(TimerStatus.START);
  }, []);

  const changeTimer = useCallback((nextTime: number) => {
    setTimer(nextTime);
    setStatus(TimerStatus.START);
  }, []);

  return {
    stop,
    time,
    status,
    start,
    reset,
    pause,
    changeTimer,
  };
}
