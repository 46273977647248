import { FC, ReactNode, useCallback } from 'react';
import styled from '@emotion/styled';
import Button from 'src/components/Button';
import ExternalLinkBase from 'src/components/ExternalLink';
import ModalLayout, { MobileModalType, ModalContent, ModalFooter, ModalHeader, ModalHeaderDescriptionRow, ModalHeaderTitle, ModalLayoutProps } from 'src/components/ModalLayout';
interface BottomSheetProps extends ModalLayoutProps {
  title: string;
  desc?: string;
  loading?: boolean;
  Contents?: ReactNode;
  buttonLabel?: string;
  onClickSubmit?: () => void;
  externalLink?: string;
  className?: string;
  buttonDisabled?: boolean;
}
const ExternalLink = styled(ExternalLinkBase)`
  text-decoration: none;
`;
const InfoModal: FC<BottomSheetProps> = ({
  isDim = true,
  loading,
  title,
  desc,
  Contents,
  buttonLabel,
  onClickSubmit,
  externalLink,
  onClose,
  closeWhenClickOutside,
  className,
  buttonDisabled = false,
  ...props
}) => {
  const ActionButton: FC = useCallback(() => {
    if (externalLink) {
      return <ModalFooter>
          <ExternalLink href={externalLink}>
            <Button id={buttonLabel || ''} disabled={buttonDisabled}>
              {buttonLabel}
            </Button>
          </ExternalLink>
        </ModalFooter>;
    }
    if (onClickSubmit) {
      return <ModalFooter>
          <Button onClick={onClickSubmit} id={buttonLabel || ''} loading={loading} disabled={buttonDisabled}>
            {buttonLabel}
          </Button>
        </ModalFooter>;
    }
    return null;
  }, [externalLink, onClickSubmit, buttonLabel, buttonDisabled, loading]);
  return <ModalLayout mobileType={MobileModalType.BOTTOM_SHEET} isDim={isDim} onClose={onClose} closeWhenClickOutside={closeWhenClickOutside} className={className} {...props} data-sentry-element="ModalLayout" data-sentry-component="InfoModal" data-sentry-source-file="index.tsx">
      {(title || desc) && <ModalHeader>
          {title && <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
              {title}
            </ModalHeaderTitle>}
          {desc && <ModalHeaderDescriptionRow>{desc}</ModalHeaderDescriptionRow>}
        </ModalHeader>}
      {Contents && <ModalContent>{Contents}</ModalContent>}
      <ActionButton data-sentry-element="ActionButton" data-sentry-source-file="index.tsx" />
    </ModalLayout>;
};
export default InfoModal;