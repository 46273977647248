import { useTranslation } from 'react-i18next';
import PreferenceButton, { PreferenceButtonProps, PreferenceButtonText } from 'src/components/Match/Preference/Button';
import useLogin from 'src/hooks/useLogin';
import LocationIconBase from './Icon';
import LocationPreferenceModal from './Modal';
import useLocationPreference from './useLocationPreference';
import { FC, ReactElement } from 'react';
export interface LocationButtonProps extends Omit<PreferenceButtonProps, 'Modal' | 'id'> {
  LocationIcon: ReactElement;
}
const LocationButton: FC<LocationButtonProps> = ({
  LocationIcon = <LocationIconBase />,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  const {
    isLogin
  } = useLogin();
  const {
    regionMatchFilterOption,
    countryMatchFilterOption
  } = useLocationPreference();
  const filterOption = regionMatchFilterOption || countryMatchFilterOption;
  return <PreferenceButton id='LOCATION' Modal={LocationPreferenceModal} {...props} data-sentry-element="PreferenceButton" data-sentry-component="LocationButton" data-sentry-source-file="Button.tsx">
      {LocationIcon}
      <PreferenceButtonText data-sentry-element="PreferenceButtonText" data-sentry-source-file="Button.tsx">
        {!isLogin || [undefined, 'DEFAULT'].includes(filterOption?.key) ? t('COUNTRY') : filterOption?.displayName}
      </PreferenceButtonText>
    </PreferenceButton>;
};
export default LocationButton;