import { FC } from 'react';
import { useAtomValue } from 'jotai';
import ObjectFitButton from 'src/components/Match/Matching/Toolbar/ObjectFitButton';
import { StringGuideDim, ToolButtonGroup, ToolbarContainer } from 'src/components/Match/Matching/Toolbar/styles';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { isShowToolbarAtom } from 'src/stores/deco/atom';
import { stepAtom } from 'src/stores/match/atoms';
import CameraOffButton from './CameraOffButton';
import DecoFilterButton from './DecoFilterButton';
import RandomEffectButton from './RandomEffectButton';
import ButtonWithGuide from './ButtonWithGuide';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
const Wrap = styled.div<{
  isVisible: boolean;
}>`
  // 스트링 가이드 애니메이션 재트리거를 막기 위해 visibility로 툴바 노출여부 설정
  visibility: ${({
  isVisible
}) => isVisible ? 'visible' : 'hidden'};
`;
const Toolbar: FC = () => {
  const cssDevice = useCssMediaDevice();
  const step = useAtomValue(stepAtom);
  const {
    t
  } = useTranslation();
  const isShowToolbar = useAtomValue(isShowToolbarAtom);
  return <Wrap isVisible={isShowToolbar && (step === 1 || cssDevice !== 'mobile')} data-testid='Toolbar' data-sentry-element="Wrap" data-sentry-component="Toolbar" data-sentry-source-file="index.tsx">
      <ToolbarContainer data-sentry-element="ToolbarContainer" data-sentry-source-file="index.tsx">
        <ToolButtonGroup data-sentry-element="ToolButtonGroup" data-sentry-source-file="index.tsx">
          {/* 카메라 오프 버튼은 빠른 시일 내에 제공되지 않을 예정이므로 스트링 가이드 스펙에서 제외 */}
          <CameraOffButton data-sentry-element="CameraOffButton" data-sentry-source-file="index.tsx" />
          <ButtonWithGuide guide={t('btn_guide_view_switch')} data-sentry-element="ButtonWithGuide" data-sentry-source-file="index.tsx">
            <ObjectFitButton data-sentry-element="ObjectFitButton" data-sentry-source-file="index.tsx" />
          </ButtonWithGuide>
        </ToolButtonGroup>
        <ToolButtonGroup data-sentry-element="ToolButtonGroup" data-sentry-source-file="index.tsx">
          <ButtonWithGuide guide={t('btn_guide_effects')} data-sentry-element="ButtonWithGuide" data-sentry-source-file="index.tsx">
            <DecoFilterButton data-sentry-element="DecoFilterButton" data-sentry-source-file="index.tsx" />
          </ButtonWithGuide>
          <ButtonWithGuide guide={t('btn_guide_random_effect')} data-sentry-element="ButtonWithGuide" data-sentry-source-file="index.tsx">
            <RandomEffectButton type='sidebar' data-sentry-element="RandomEffectButton" data-sentry-source-file="index.tsx" />
          </ButtonWithGuide>
        </ToolButtonGroup>
      </ToolbarContainer>
      <StringGuideDim data-testid='Toolbar-String-Guide-Dim' data-sentry-element="StringGuideDim" data-sentry-source-file="index.tsx" />
    </Wrap>;
};
export default Toolbar;