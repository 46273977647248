import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { nextMatchDisableShowAtom } from 'src/stores/match/atoms';
import { mouseHoverCss } from 'src/utils/styled';
const RootStyle = styled.button`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s;
  background-color: ${({
  theme
}) => theme.color.white};
  cursor: pointer;
  color: ${({
  theme
}) => theme.color.gray60__dkGray100};
  // 가장 길이가 짧은 한글 기준
  min-width: 76px;
  &:disabled {
    background-color: ${({
  theme
}) => theme.color.gray800__dkGray900};
    color: ${({
  theme
}) => theme.color.gray700__dkGray700};
    cursor: default;
  }
  ${({
  theme
}) => mouseHoverCss(`background-color: ${theme.color.gray700__dkGray700};`)}
`;
const NextMatchButtonBase: React.FC<React.HTMLAttributes<HTMLButtonElement>> = props => {
  const nextMatchDisableShow = useAtomValue(nextMatchDisableShowAtom);
  return <RootStyle {...props} disabled={!!nextMatchDisableShow} data-sentry-element="RootStyle" data-sentry-component="NextMatchButtonBase" data-sentry-source-file="NextMatchButtonBase.tsx" />;
};
export default NextMatchButtonBase;