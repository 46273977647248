import 'src/utils/polyfill';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { debounceTime, fromEvent } from 'rxjs';
import ErrorComponent from 'src/components/Error';
import FirstOpenManager from 'src/components/FirstOpenManager';
import HeartbeatManager from 'src/components/HeartbeatManager';
import Layout from 'src/components/Layout';
import UTMManager from 'src/components/UTMManager';
import useIsPwa from 'src/hooks/useIsPwa';
import useIsRtl from 'src/hooks/useIsRtl';
import store from 'src/stores';
import theme from 'src/styles/theme';
import { PageMetas } from 'src/types/meta';
import { googleAppId } from 'src/types/OAuthToken';
import getDeviceInfo from 'src/utils/device/info';
import { LanguageProvider } from 'src/utils/language';
import { setViewportUnits } from 'src/utils/layout';
import { isAzarJsV2 } from 'src/utils/webview';
import { ErrorBoundary } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import getCanonicalUrl from 'src/utils/canonicalUrl';
import 'src/styles/globals.css';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});
const HydrateAtoms = ({
  children
}: {
  children: ReactNode;
}) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return <>{children}</>;
};
const Debug = dynamic(() => import('src/components/Debug').catch(() => new Promise<React.FC>(resolve => resolve(() => <></>))), {
  ssr: false,
  suspense: false
});
interface PageProps extends PageMetas {
  children?: React.ReactNode;
}
interface Props extends AppProps {
  pageProps: PageProps;
}
export const oneTrustToken = process.env.NEXT_PUBLIC_ONETRUST || '';
const DEFAULT_META_VIEWPORT_CONTENT = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
function MyApp({
  Component,
  pageProps
}: Props) {
  const isRtl = useIsRtl();
  const {
    metas = [{
      property: 'og:title',
      content: 'OG_TITLE',
      key: 'og:title'
    }, {
      name: 'description',
      content: 'OG_DESC',
      key: 'description'
    }, {
      property: 'og:description',
      content: 'OG_DESC',
      key: 'og:description'
    }, {
      property: 'og:image',
      content: '/images/og/azarOgImage.png',
      key: 'og:image'
    }]
  } = pageProps;
  const router = useRouter();

  // 현재 페이지의 경로
  const pathname = router.pathname;

  // URL의 쿼리 파라미터
  const query = router.query;
  const [userInteracted, setUserInteracted] = useState(false);
  const {
    isMobile,
    isWebKit
  } = getDeviceInfo();
  const isAndroid = isMobile && !isWebKit;
  useEffect(() => {
    const onInitialInteraction = () => {
      setUserInteracted(true);
      window.removeEventListener('pointerdown', onInitialInteraction);
    };
    window.addEventListener('pointerdown', onInitialInteraction);
    setViewportUnits();
    const subscription = fromEvent(window.visualViewport ?? window, 'resize').pipe(debounceTime(100)).subscribe(() => {
      setViewportUnits();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    document.body.classList.add('init');
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'Prod') {
      if (process.env.NEXT_PUBLIC_RELEASE) {
        // eslint-disable-next-line no-console
        console.log(`Build version: ${process.env.NEXT_PUBLIC_RELEASE}`);
      }
    }
  }, []);
  useIsPwa();

  /**
   * https://stackoverflow.com/questions/76026292/why-is-window-innerheight-incorrect-until-i-tap-chrome-android
   * 안드로이드 Chrome 환경에서 window.innerHeight가 정상값보다 크게 측정되고 최초 터치 이후 정상화되는 이슈
   *
   * interactive-widget 키 지원: Blink 엔진 기반 Chrome 180 버전 이상 (iOS는 Webkit 기반 동작이기 때문에 영향 없음)
   * https://developer.chrome.com/blog/viewport-resize-behavior?hl=ko#:%7E:text=Through%20the%20interactive-widget%20key,Visual%20Viewport%20and%20Layout%20Viewport.
   */
  const metaViewportInteractive = useMemo(() => isAndroid && !userInteracted ? ', interactive-widget=resizes-content' : '', [isAndroid, userInteracted]);
  /**
   * viewport-fit=cover 웹뷰에서 사용시 ios에서 safe-area를 사용할 수 있습니다.
   * iOS 11.2 이상
   * env(safe-area-inset-top);
   * env(safe-area-inset-right);
   * env(safe-area-inset-bottom);
   * env(safe-area-inset-left);
   *
   * iOS 11.0 버전
   * constant() 사용
   */
  const metaViewportFitCover = useMemo(() => isAzarJsV2() && !isAndroid ? ', viewport-fit=cover' : '', [isAndroid]);
  const metaViewportContent = `${DEFAULT_META_VIEWPORT_CONTENT}${metaViewportInteractive}${metaViewportFitCover}`;
  return <>
      <Script type='text/javascript' strategy='beforeInteractive' src={`https://cdn.cookielaw.org/consent/${oneTrustToken}/OtAutoBlock.js`} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Script id='ga' dangerouslySetInnerHTML={{
      __html: `
            (function(dataLayer, gtmId) {
              window[dataLayer] = window[dataLayer] || [];
              window[dataLayer].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
              });
              const firstScriptElement = document.getElementsByTagName('script')[0];
              const scriptElement = document.createElement('script');
              const dataLayerQuery = dataLayer !== 'dataLayer' ? '&l=' + dataLayer : '';

              scriptElement.async = true;
              scriptElement.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dataLayerQuery;
              firstScriptElement.parentNode.appendChild(scriptElement, firstScriptElement);
            })('dataLayer', 'GTM-WQT6PKJ')
            function gtag(){dataLayer.push(arguments);}
            function addPagePageViewGtag(viewConfig){gtag('config', 'GTM-WQT6PKJ', viewConfig)}
            `
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name='facebook-domain-verification' content='obrg4zuhup370msixm63loi0g8abga' data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name='viewport' content={metaViewportContent} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        {pageProps.noindex && <meta name='robots' content='noindex, nofollow' />}
        {pageProps.canonical && <link rel='canonical' href={getCanonicalUrl(pathname, query)} />}
      </Head>
      <GoogleOAuthProvider clientId={googleAppId} data-sentry-element="GoogleOAuthProvider" data-sentry-source-file="_app.tsx">
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <JotaiProvider store={store} data-sentry-element="JotaiProvider" data-sentry-source-file="_app.tsx">
            <DevTools store={store} data-sentry-element="DevTools" data-sentry-source-file="_app.tsx" />
            {/* HydrateAtoms queryClient를 inject해주는 역할 */}
            <HydrateAtoms data-sentry-element="HydrateAtoms" data-sentry-source-file="_app.tsx">
              <ThemeProvider theme={{
              ...theme,
              isRtl
            }} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
                <Layout metas={metas} data-sentry-element="Layout" data-sentry-source-file="_app.tsx">
                  <Debug data-sentry-element="Debug" data-sentry-source-file="_app.tsx" />
                  <UTMManager data-sentry-element="UTMManager" data-sentry-source-file="_app.tsx" />
                  <FirstOpenManager data-sentry-element="FirstOpenManager" data-sentry-source-file="_app.tsx" />
                  <HeartbeatManager data-sentry-element="HeartbeatManager" data-sentry-source-file="_app.tsx" />
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </Layout>
                <ReactQueryDevtools data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
              </ThemeProvider>
            </HydrateAtoms>
          </JotaiProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </>;
}
function WithLanguageProvider(props: Props) {
  return <ErrorBoundary fallback={() => <ErrorComponent />} data-sentry-element="ErrorBoundary" data-sentry-component="WithLanguageProvider" data-sentry-source-file="_app.tsx">
      <LanguageProvider data-sentry-element="LanguageProvider" data-sentry-source-file="_app.tsx">
        <MyApp {...props} data-sentry-element="MyApp" data-sentry-source-file="_app.tsx" />
      </LanguageProvider>
    </ErrorBoundary>;
}
export default WithLanguageProvider;