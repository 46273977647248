import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import BottomTabBar from 'src/components/BottomTabBar';
import FooterBase from 'src/components/Footer';
import Header from 'src/components/Header';
import Matching from 'src/components/Match/Matching';
import { statusAtom, stepAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { isDecoSupportedAtom } from 'src/stores/deco/atom';
const Container = styled.div<{
  isShowBottomTabBar: boolean;
}>`
  display: flex;
  height: var(--100vh);
  ${({
  isShowBottomTabBar,
  theme
}) => isShowBottomTabBar && `${theme.screenSize.tablet} {
    height:  calc(var(--100vh) - 56px);
  }`}
`;
const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Footer = styled(FooterBase)`
  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-bottom: 56px;
  }
`;
const MatchView: FC = () => {
  const step = useAtomValue(stepAtom);
  const status = useAtomValue(statusAtom);
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    if (status === STATUS.INITIAL && isDecoSupported !== undefined) {
      pushEvent({
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR_REPORT_EFFECT_AVAILABLE,
        eventParams: {
          action_category: 'report',
          tab: 'mirror',
          page: 'main',
          target: 'effect_available',
          effect_available: isDecoSupported
        }
      });
    }
  }, [status, pushEvent, isDecoSupported]);
  return <Wrap data-sentry-element="Wrap" data-sentry-component="MatchView" data-sentry-source-file="Match.tsx">
      <Container isShowBottomTabBar={step === 1} data-sentry-element="Container" data-sentry-source-file="Match.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="Match.tsx" />
        <Matching data-sentry-element="Matching" data-sentry-source-file="Match.tsx" />
      </Container>
      <Footer data-sentry-element="Footer" data-sentry-source-file="Match.tsx" />
      {step === 1 && <BottomTabBar />}
    </Wrap>;
};
export default MatchView;