import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Gap from 'src/components/Gap';
import PreferenceButton, { PreferenceButtonProps } from 'src/components/Match/Preference/Button';
import useLogin from 'src/hooks/useLogin';
import GenderIconBase from './Icon';
import GenderPreferenceModal from './Modal';
import useGenderPreference from './useGenderPreference';
import { FC } from 'react';
export interface GenderButtonProps extends Omit<PreferenceButtonProps, 'Modal' | 'id'> {
  GenderIcon: React.FC;
}
const GenderButtonText = styled.span`
  color: inherit;
  font-size: inherit;
  ${({
  theme
}) => theme.screenSize.desktop} {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }
`;
const GenderButton: FC<GenderButtonProps> = ({
  GenderIcon = GenderIconBase,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  const {
    isLogin
  } = useLogin();
  const {
    genderMatchFilterSelection,
    genderMatchFilterOption
  } = useGenderPreference();
  return <PreferenceButton id='GENDER' Modal={GenderPreferenceModal} {...props} data-sentry-element="PreferenceButton" data-sentry-component="GenderButton" data-sentry-source-file="Button.tsx">
      <GenderIcon gender={!isLogin || !genderMatchFilterSelection?.optionKey ? 'ALL' : genderMatchFilterSelection?.optionKey} data-sentry-element="GenderIcon" data-sentry-source-file="Button.tsx" />
      <Gap width={8} data-sentry-element="Gap" data-sentry-source-file="Button.tsx" />
      <GenderButtonText data-sentry-element="GenderButtonText" data-sentry-source-file="Button.tsx">
        {!isLogin || [undefined, 'ALL', 'BOTH'].includes(genderMatchFilterOption?.key) ? t('GENDER') : genderMatchFilterOption?.displayName}
      </GenderButtonText>
    </PreferenceButton>;
};
export default GenderButton;