import { useSetAtom } from 'jotai';
import { useRestQuery } from 'src/hooks/useRestQuery';
import { PhoneNumberLoginApiList } from 'src/stores/phoneNumberLogin/apis';
import { getLanguageCode } from 'src/utils/language';
import { VerifyPreparationRequest, VerifyPreparationResponse } from 'src/stores/phoneNumberLogin/types';
import { ApiResponse } from 'src/utils/api';
import { smsPhoneNumberAtom } from 'src/stores/phoneNumberLogin/atoms';
import { Platform } from 'src/types/Platform';
import { useEffect, useMemo } from 'react';
export default function useVerifyPreparation() {
  const setSmsPhoneNumber = useSetAtom(smsPhoneNumberAtom);
  const preparationQuery = useRestQuery<VerifyPreparationRequest, ApiResponse<VerifyPreparationResponse>, VerifyPreparationRequest>({
    path: PhoneNumberLoginApiList.PhoneVerificationPreparation,
    params: {
      languageCode: getLanguageCode().toLowerCase(),
      platform: Platform.WebClient
    },
    options: {
      enabled: true
    }
  });
  const {
    data,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
    isFetching
  } = preparationQuery;
  const list = useMemo(() => data?.data.result.userLocationInfos || [], [data?.data.result.userLocationInfos]);
  const selectorList = list.map(country => ({
    value: country.countryName || '',
    label: `+${country.countryPrefixNumber} (${country.countryName})`
  }));
  useEffect(() => {
    const defaultLocation = list[0];
    if (defaultLocation) {
      setSmsPhoneNumber(prevSmsPhoneNumber => ({
        ...prevSmsPhoneNumber,
        verifyPreparation: defaultLocation
      }));
    }
  }, [list, setSmsPhoneNumber]);
  const isError = useMemo(() => isQueryError || isQuerySuccess && !list[0], [isQueryError, isQuerySuccess, list]);
  return {
    options: selectorList,
    prefixList: list,
    isError,
    isFetching
  };
}