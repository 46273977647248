//가입한유저 &&  등록된 이메일 있음 && 인증된 이메일이 없음
import { FC } from 'react';
import Template from './Template';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import { useSetAtom } from 'jotai';
import { nextStepAtom } from 'src/stores/register/atoms';
import EmailAddressRegisterStep from './EmailAddressRegisterStep';
import { addStepsOnPhoneNumberLoginStepsAtom, isRemoveOldDeviceUserDetailsAtom, PhoneNumberRegisterSteps } from 'src/stores/phoneNumberLogin/atoms';
import useDeviceTransferSendCode from 'src/hooks/useDeviceTransferSendCode';
import EmailVerificationVerifyCodeStep from './EmailVerificationVerifyCodeStep';
import { useTranslation } from 'react-i18next';

/** 이메일 인증을 진행할지, 새로 계정을 생성할 지 선택  */
const EmailVerificationSelectStep: FC = () => {
  const helpCenterUrl = useHelpCenterUrl();
  const addSteps = useSetAtom(addStepsOnPhoneNumberLoginStepsAtom);
  const goNextStep = useSetAtom(nextStepAtom);
  const setRemoveOldDeviceUserDetails = useSetAtom(isRemoveOldDeviceUserDetailsAtom);
  const {
    sendCode,
    isLoading
  } = useDeviceTransferSendCode();
  // 기존 등록된 미인증 이메일로 인증 진행
  const handleMainClick = async () => {
    await sendCode();
    addSteps(EmailVerificationVerifyCodeStep);
    goNextStep();
  };
  // 기존 이메일 정보 삭제하고 새롭게 이메일 등록 및 인증
  const handleSubClick = () => {
    setRemoveOldDeviceUserDetails(true);
    addSteps(EmailAddressRegisterStep, ...PhoneNumberRegisterSteps);
    goNextStep();
  };
  const {
    t
  } = useTranslation();
  return <Template eventId='signup_phone_not_verified_but_registered_title' title={t('signup_phone_not_verified_but_registered_title')} description={t('signup_phone_not_verified_but_registered_desc')} link={helpCenterUrl} mainButtonProps={{
    onClick: handleMainClick,
    text: t('more__send_code'),
    loading: isLoading
  }} subButtonProps={{
    onClick: handleSubClick,
    text: t('signup_phone_btn_reg')
  }} linkText={t('signup_phone_btn_helpcenter')} data-sentry-element="Template" data-sentry-component="EmailVerificationSelectStep" data-sentry-source-file="EmailVerificationSelectStep.tsx" />;
};
export default EmailVerificationSelectStep;