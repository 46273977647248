import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { grantVideoAtom } from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import useLogin from 'src/hooks/useLogin';
import { ModalType } from 'src/types/Modal';
import { EffectsModal } from 'src/components/Effects';
import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
const useOpenEffectByUrlQuery = () => {
  const showModal = useSetAtom(showModalAtom);
  const grantVideo = useSetAtom(grantVideoAtom);
  const router = useRouter();
  const {
    isLogin
  } = useLogin();
  useEffect(() => {
    if (!isLogin) return;
    if (!router.isReady) return;
    if (router.query?.action !== 'open-effect') return;
    grantVideo({
      onSuccess: () => {
        showModal({
          key: ModalType.DECO_STUDIO,
          component: EffectsModal
        });
      },
      errorModal: PermissionErrorFullModal
    });
  }, [isLogin, router.isReady, router.query, showModal, grantVideo]);
};
export default useOpenEffectByUrlQuery;