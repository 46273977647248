import { FC } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { ModalFooter } from 'src/components/ModalLayout';
import { Description, ErrorCode as ErrorCodeBase, ImgFailed, Wrapper as WrapperBase } from 'src/components/Payments/Failure/style';
import { getGemProductsAtom } from 'src/stores/payments/atoms';
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(WrapperBase)`
  padding-top: 40px;
  padding-bottom: 12px;
  flex: 1;
`;
const ErrorCode = styled(ErrorCodeBase)`
  margin-bottom: 12px;
`;
const ProductLoadingFailure: FC = () => {
  const {
    t
  } = useTranslation();
  const getGemProducts = useSetAtom(getGemProductsAtom);
  return <Container data-sentry-element="Container" data-sentry-component="ProductLoadingFailure" data-sentry-source-file="ProductLoadingFailure.tsx">
      <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="ProductLoadingFailure.tsx">
        <ImgFailed src='/images/icons/imgFailed.png' alt='failed' data-sentry-element="ImgFailed" data-sentry-source-file="ProductLoadingFailure.tsx" />
        <ErrorCode data-sentry-element="ErrorCode" data-sentry-source-file="ProductLoadingFailure.tsx">{t('common__problem_occurred')}</ErrorCode>
        <Description data-sentry-element="Description" data-sentry-source-file="ProductLoadingFailure.tsx">{t('common__try_again')}</Description>
      </Wrapper>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="ProductLoadingFailure.tsx">
        <Button id='gem-products-retry' onClick={getGemProducts} data-sentry-element="Button" data-sentry-source-file="ProductLoadingFailure.tsx">
          {t('common__try_again_button')}
        </Button>
      </ModalFooter>
    </Container>;
};
export default ProductLoadingFailure;