import { FC, Fragment, useContext, useMemo } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import GapBase from 'src/components/Gap';
import { STATUS } from 'src/types/Match';
import { hexToRgb } from 'src/utils/common';
import { LanguageContext } from 'src/utils/language';
interface IPeerHeroVideo {
  status: STATUS;
}
const Gap = styled(GapBase)`
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: ${8 / 360 * 100}%;
  }
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: ${8 / 360 * 100}%;
  }
`;
const RotateWrap = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: none;
  }
`;
const MobileWrap = styled.div`
  display: none;
  ${({
  theme
}) => theme.screenSize.tablet} {
    display: flex;
  }
  height: 100%;
  width: calc(100% * (190 / 180));
  position: absolute;
`;
const CardImageWrap = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: row;

  ${({
  theme
}) => theme.screenSize.tablet} {
    margin-left: 0;
    margin-top: 0;
  }
`;
const ScrollAnimUp = (y: string) => keyframes`
  0% {
    background-position-y: 0%;
  }
  100% {
    background-position-y: ${y};
  }
`;
const ScrollAnimDown = (y: string) => keyframes`
  0% {
    background-position-y: ${y};
  }
  100% {
    background-position-y: 0;
  }
`;
const ScrollAnimUpWithMargin = (margin: string) => ScrollAnimUp(`calc(100% - (var(--100vh) - ${margin}))`);
const ScrollAnimDownWithMargin = (margin: string) => ScrollAnimDown(`calc(100% - (var(--100vh) - ${margin}))`);
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({
  theme
}) => `linear-gradient(180deg, ${theme.color.gray30__dkGray50} 0%, rgba(${hexToRgb(theme.color.gray30__dkGray50)}, 0.3) 17.42%, rgba(${hexToRgb(theme.color.gray30__dkGray50)}, 0.3) 73.12%, ${theme.color.gray30__dkGray50} 100%)`};
`;
const CardBackground = styled.div<{
  direction: string;
  src: string;
}>`
  flex-grow: 1;
  height: 100%;
  background: ${({
  src
}) => `url(${src})`};
  background-repeat: repeat-y;
  background-size: 100%;
  animation-duration: 32s;
  ${({
  theme
}) => theme.screenSize.tablet} {
    animation-duration: 30s;
  }
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  ${({
  direction,
  theme
}) => direction === 'up' ? css`
          animation-name: ${ScrollAnimUpWithMargin('80px')};
          ${theme.screenSize.tablet} {
            animation-name: ${ScrollAnimUpWithMargin('56px')};
          }
        ` : css`
          animation-name: ${ScrollAnimDownWithMargin('80px')};
          ${theme.screenSize.tablet} {
            animation-name: ${ScrollAnimDownWithMargin('56px')};
          }
        `}
`;
const FindingBackground = styled(CardBackground)`
  ${({
  theme
}) => css`
    animation-name: ${ScrollAnimDownWithMargin('152px')};
    ${theme.screenSize.tablet} {
      animation-name: ${ScrollAnimDownWithMargin('0px')};
    }
  `}
`;
const MatchingCardBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
`;
const PeerHeroVideo: FC<IPeerHeroVideo> = ({
  status
}) => {
  const {
    language
  } = useContext(LanguageContext);
  const cardLocale = useMemo(() => language === 'hi' ? 'IN' : 'US', [language]);
  const renderCards = (dirs: ('up' | 'down')[], src: 'desktop' | 'mobile') => dirs.map((direction, i) => <Fragment key={`card-background-${i}`}>
        {i !== 0 && <Gap width={8} />}
        <CardBackground direction={direction} src={`/images/match/cardImage/${src}${cardLocale}0${i + 1}.png`} />
      </Fragment>);
  return <>
      {status === STATUS.INITIAL ? <>
          <RotateWrap>
            <CardImageWrap>
              {renderCards(['up', 'down', 'up'], 'desktop')}
            </CardImageWrap>
          </RotateWrap>
          <MobileWrap>
            <CardImageWrap>
              {renderCards(['up', 'down'], 'mobile')}
              <Overlay />
            </CardImageWrap>
          </MobileWrap>
        </> : <MatchingCardBackground>
          <FindingBackground direction='down' src='/images/match/finding-blur.png' />
        </MatchingCardBackground>}
    </>;
};
export default PeerHeroVideo;