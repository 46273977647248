import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mouseHoverCss } from 'src/utils/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { isApplyingEffectAtom, removeDecoEffectAtom } from 'src/stores/deco/atom';
const RemoveButtonText = styled.span`
  color: ${({
  theme
}) => theme.color.white};
  font-size: 12px;
  font-weight: 700;
`;
const RemoveButton = styled.button`
  z-index: 1;
  position: absolute;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 36px;
  top: 298px;
  display: flex;
  align-items: center;
  height: 31px;
  padding: 0px 10px;
  border-radius: 16px;
  background-color: rgba(34, 34, 34, 0.8);
  cursor: pointer;

  ${mouseHoverCss(`
    background-color: rgba(34, 34, 34, 1);
  `)}
  :disabled {
    ${mouseHoverCss(`
      background-color: rgba(34, 34, 34, 0.8);
    `)}
    ${RemoveButtonText} {
      opacity: 0.4;
    }
  }

  ${({
  theme
}) => theme.screenSize.tablet} {
    top: -45px;
    ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 16px;
  }
`;
const RemoveEffectButton: FC = () => {
  const {
    t
  } = useTranslation();
  const removeEffects = useSetAtom(removeDecoEffectAtom);
  const isApplyingEffect = useAtomValue(isApplyingEffectAtom);
  return <RemoveButton disabled={!isApplyingEffect} onClick={removeEffects} data-testid='Effect-Remove-Button' data-sentry-element="RemoveButton" data-sentry-component="RemoveEffectButton" data-sentry-source-file="RemoveEffectButton.tsx">
      <RemoveButtonText data-sentry-element="RemoveButtonText" data-sentry-source-file="RemoveEffectButton.tsx">{t('deco_studio_btn_remove')}</RemoveButtonText>
    </RemoveButton>;
};
export default RemoveEffectButton;