import styled from '@emotion/styled';
import Button from 'src/components/Button';
import { ModalHeaderDescriptionRow as ModalHeaderDescBase } from 'src/components/ModalLayout';
export interface ModalProps {
  onSave: () => void;
  onClose?: () => void;
  landFrom: 'MIRROR' | 'FINDING';
}
export const ModalHeaderDescriptionRow = styled(ModalHeaderDescBase)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;
export const ButtonErrorText = styled.span`
  font-weight: 400;
  margin-top: 1.36px;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
`;
export const SaveButton = styled(Button)`
  margin-top: 18px;
`;