import { FC } from 'react';
import useEffectButton from './useEffectButton';
import { DecoEffect, GESTURE_EFFECT_TRANSLATION_KEYS } from 'src/types/Deco';
import styled from '@emotion/styled';
import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { sendGestureEffectToggleEventAtom } from 'src/stores/deco/atom';
interface Props {
  effect: DecoEffect;
}
const DesktopWrap = styled.div`
  width: 100%;
  padding: 3px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const EffectThumbnailWrap = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({
  theme
}) => theme.color.gray200__dkGray200};
`;
const EffectThumbnail = styled.img<{
  isAvailable: boolean;
}>`
  width: 28px;
  height: 28px;
  opacity: ${({
  isAvailable
}) => isAvailable ? 1 : 0.4};

  ${({
  theme
}) => theme.screenSize.tablet} {
    width: 24px;
    height: 24px;
  }
`;
const LoadingIndicator = styled(LoadingIndicatorBase)`
  cursor: auto;
`;
const EffectInfoWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const EffectName = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};

  ${({
  theme
}) => theme.screenSize.tablet} {
    font-weight: 400;
  }
`;
const EffectDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
`;
const ToggleCircle = styled.span`
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({
  theme
}) => theme.color.white};
  transition: all 0.3s;
`;
const ToggleButton = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  padding: 2px;
  border-radius: 16px;
  background-color: ${({
  isActive,
  theme
}) => isActive ? theme.color.green500 : theme.color.gray100__dkGray150};
  cursor: pointer;
  flex-shrink: 0;

  ${ToggleCircle} {
    transform: ${({
  isActive
}) => isActive ? 'translateX(20px)' : 'translateX(0)'};
  }
`;
const MobileToggleEffectButton = styled.button<{
  isActive: boolean;
}>`
  all: unset;
  width: fit-content;
  height: 48px;
  padding: 0px 14px;
  box-sizing: border-box;
  background-color: ${({
  theme
}) => theme.color.gray200__dkGray200};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;

  ${({
  isActive,
  theme
}) => `
    border: 2px solid ${isActive ? theme.color.green500 : theme.color.gray200__dkGray200};
  `}
`;
const MobileLoaderWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const MobileLoadingIndicator = styled(LoadingIndicator)`
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};
`;
const ToggleEffectButton: FC<Props> = ({
  effect
}) => {
  const {
    isActive,
    isAvailable,
    isLoading,
    activateEffect,
    deactivateEffect,
    withEffectAvailableCheck
  } = useEffectButton(effect);
  const sendGestureEffectToggleEvent = useSetAtom(sendGestureEffectToggleEventAtom);
  const {
    t
  } = useTranslation();
  const isDesktopLayout = useCssMediaDevice() === 'desktop';
  const onToggleEffect = withEffectAvailableCheck(() => {
    sendGestureEffectToggleEvent(effect, !isActive, 'click');
    if (isActive) {
      deactivateEffect();
    } else {
      activateEffect();
    }
  });
  const effectTitle = t(GESTURE_EFFECT_TRANSLATION_KEYS[effect.effectId]?.title ?? '');
  const effectGuide = t(GESTURE_EFFECT_TRANSLATION_KEYS[effect.effectId]?.description ?? '');
  const isShowLoader = isLoading && isActive;
  if (isDesktopLayout) {
    return <DesktopWrap data-testid={`Effect-Wrap-${effect.effectId}`}>
        <EffectThumbnailWrap>
          {isShowLoader ? <LoadingIndicator size={28} data-testid='Effect-Button-Loading' /> : <EffectThumbnail src={effect.thumbnailImageUrl} alt={effectTitle} isAvailable={isAvailable} />}
        </EffectThumbnailWrap>
        <EffectInfoWrap>
          <EffectName>{effectTitle}</EffectName>
          <EffectDescription>{effectGuide}</EffectDescription>
        </EffectInfoWrap>
        <ToggleButton isActive={isActive} onClick={onToggleEffect} data-testid={`Effect-Button-${effect.effectId}`} data-isActive={isActive}>
          <ToggleCircle />
        </ToggleButton>
      </DesktopWrap>;
  } else {
    return <MobileToggleEffectButton isActive={isActive} onClick={onToggleEffect} data-testid={`Effect-Button-${effect.effectId}`} data-isActive={isActive}>
        <EffectThumbnail src={effect.thumbnailImageUrl} alt={effectTitle} isAvailable={isAvailable} />
        <EffectName>{effectTitle}</EffectName>
        {isShowLoader && <MobileLoaderWrap>
            <MobileLoadingIndicator size={24} data-testid='Effect-Button-Loading' />
          </MobileLoaderWrap>}
      </MobileToggleEffectButton>;
  }
};
export default ToggleEffectButton;