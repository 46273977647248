import { FC } from 'react';
import { ToolButton, ToolButtonIcon } from './styles';
import { useAtomValue, useSetAtom } from 'jotai';
import { applyRandomEffectAtom, handleUnsupportedDecoAccessAtom, isDecoSupportedAtom, isLoadingEffectAtom, isRandomEffectAtom } from 'src/stores/deco/atom';
import useCallbackWithCheckLogin from 'src/hooks/useCallbackWithCheckLogin';
import { eventMatchPageAtom, grantVideoAtom } from 'src/stores/match/atoms';
import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
import useEvent from 'src/hooks/useEvent';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import styled from '@emotion/styled';
import useEventLoginModalVisitorTrigger from 'src/hooks/useEventLoginModalVisitorTrigger';
const LoadingIndicator = styled(LoadingIndicatorBase)`
  position: relative;
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: 20px;
    height: 20px;
  }
`;
interface Props {
  type: 'sidebar' | 'bottombar';
}
const RandomEffectButton: FC<Props> = ({
  type
}) => {
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const isLoadingEffect = useAtomValue(isLoadingEffectAtom);
  const isRandomEffect = useAtomValue(isRandomEffectAtom);
  const handleUnsupportedDecoAccess = useSetAtom(handleUnsupportedDecoAccessAtom);
  const grantVideo = useSetAtom(grantVideoAtom);
  const applyRandomEffect = useSetAtom(applyRandomEffectAtom);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const pushEvent = useEvent();
  const setEventLoginModalVisitorTrigger = useEventLoginModalVisitorTrigger();
  const isLoadingRandomEffect = isLoadingEffect && isRandomEffect;
  const handleClickRandomEffect = useCallbackWithCheckLogin(async () => {
    if (isLoadingRandomEffect) return;
    if (!isDecoSupported) {
      handleUnsupportedDecoAccess();
      return;
    }
    const onSuccess = () => {
      const appliedEffect = applyRandomEffect();
      if (!appliedEffect) return;
      pushEvent({
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR__CLICK_RANDOM_EFFECT,
        eventParams: {
          action_category: 'click',
          tab: 'mirror',
          page: eventMatchPage,
          target: 'random_effect',
          type,
          effect_id: appliedEffect.effectId
        }
      });
    };
    await grantVideo({
      onSuccess,
      errorModal: PermissionErrorFullModal
    });
  });
  const onClick = () => {
    setEventLoginModalVisitorTrigger(EVENT_LOGIN_MODAL_VISITOR_TRIGGER.RANDOM_EFFECT_BUTTON);
    handleClickRandomEffect();
  };
  return <ToolButton isAvailable={isDecoSupported} onClick={onClick} data-testid='Effect-Random-Button' data-sentry-element="ToolButton" data-sentry-component="RandomEffectButton" data-sentry-source-file="RandomEffectButton.tsx">
      {isLoadingRandomEffect ? <LoadingIndicator size={24} /> : <ToolButtonIcon src='/images/icons/icQuestionMark.svg' alt='Random Effect' />}
    </ToolButton>;
};
export default RandomEffectButton;