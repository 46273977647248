import { createContext, PropsWithChildren, FC, useEffect, useState } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import defaultTranslation from '../../translations/translations.en.json';
export const getLanguageCode = () => ((typeof window !== 'undefined' && window.AzarJs ? window.AzarJs.getUiLocale?.() : undefined) || navigator.language).substring(0, 2);
export enum SupportLanguage {
  AR = 'ar',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  ES_MX = 'es_MX',
  FR = 'fr',
  HI = 'hi',
  ID = 'id',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NL = 'nl',
  PT = 'pt',
  PT_BR = 'pt_BR',
  RU = 'ru',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  VI = 'vi',
  ZH_CN = 'zh_CN',
  ZH_RTW = 'zh_rTW',
}
const DEFAULT_LANGUAGE = SupportLanguage.EN;
export const defaultI18Instance = () => {
  const translation = defaultTranslation;
  i18next.use(initReactI18next).init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    resources: {
      [DEFAULT_LANGUAGE]: {
        translation
      }
    }
  });
  return i18next;
};
export const initializeI18next = async (locale: SupportLanguage = DEFAULT_LANGUAGE) => {
  let translation;
  try {
    translation = await import(`../../translations/translations.${locale}.json`);
  } catch (error) {
    translation = defaultTranslation;
  }
  i18next.use(initReactI18next).init({
    lng: locale,
    fallbackLng: DEFAULT_LANGUAGE,
    resources: {
      [locale]: {
        translation
      }
    }
  });
  return i18next;
};
export const getLocale: () => SupportLanguage = () => {
  const userLanguage = getLanguageCode() as SupportLanguage;
  return Object.values(SupportLanguage).includes(userLanguage) ? userLanguage : DEFAULT_LANGUAGE;
};
const getI18nLocale = (): SupportLanguage => {
  const language = ((typeof window !== 'undefined' && window.AzarJs ? window.AzarJs.getUiLocale?.() : undefined) || navigator.language).replace(/-/g, '_') as SupportLanguage;
  const supportedLanguages = Object.values(SupportLanguage);
  if (supportedLanguages.includes(language)) return language;

  // ISO 639-1 언어코드
  const twoCharacterLanguage = language.substring(0, 2) as SupportLanguage;
  return supportedLanguages.includes(twoCharacterLanguage) ? twoCharacterLanguage : DEFAULT_LANGUAGE;
};
const initValue: {
  language?: SupportLanguage;
  i18nLanguage?: SupportLanguage;
  changeLanguage: (language: SupportLanguage) => void;
  isRtl: boolean;
} = {
  changeLanguage: () => {},
  isRtl: false
};
export const LanguageContext = createContext(initValue);
export const LanguageProvider: FC<PropsWithChildren> = props => {
  const [language, setLanguage] = useState<SupportLanguage | undefined>();
  const [i18nLanguage, setI18nLanguage] = useState<SupportLanguage | undefined>();
  useEffect(() => {
    setLanguage(getLocale());
    setI18nLanguage(getI18nLocale());
  }, []);
  return <LanguageContext.Provider value={{
    language,
    i18nLanguage,
    changeLanguage: setLanguage,
    isRtl: language === 'ar'
  }} data-sentry-element="unknown" data-sentry-component="LanguageProvider" data-sentry-source-file="language.tsx">
      {props.children}
    </LanguageContext.Provider>;
};