import styled from '@emotion/styled';
export const RootStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({
  theme
}) => theme.color.gray30__dkGray50};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  padding-top: 32px;
  width: 100%;
  max-width: 650px;
  height: 100%;
  background-color: ${({
  theme
}) => theme.color.gray60__dkGray100};
  flex-direction: column;
`;
export const Wrapper = styled.div`
  width: 100%;
  flex: 1;
`;
export const LoadingOverlay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({
  theme
}) => theme.color.white};
  padding: 16px 24px 16px 24px;
`;
export const Divider = styled.div`
  height: 1px;
  background-color: ${({
  theme
}) => theme.color.gray200__dkGray200};
  margin: 24px;
`;
export const PaymentConfirmButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({
  theme
}) => theme.color.gray60__dkGray100};
  padding: 12px 24px 32px 24px;
`;
export const ProductLabelStyled = styled.div`
  color: ${({
  theme
}) => theme.color.white};
`;
export const ProductLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 24px 20px 24px;
  height: 60px;
`;