import { FC } from 'react';
import ChatLayout from 'src/components/ChatLayout';
import ChatNickName from 'src/components/ChatNickName';
import { Chat as ChatType } from 'src/types/Chat';
import { filterPrivacy } from 'src/utils/common';
import { NickNameWrap, TextWrap } from './style';
type ChatProps = ChatType;
const Chat: FC<ChatProps> = props => {
  if (!(props.type === 'azar_message')) {
    return null;
  }
  const chat = props;
  if (!chat.message || !chat.sender) {
    return null;
  }
  const [name, iconSource] = [chat.sender.simpleName, chat.sender.profileImageUrl];
  return <ChatLayout iconSource={iconSource} data-sentry-element="ChatLayout" data-sentry-component="Chat" data-sentry-source-file="Chat.tsx">
      <NickNameWrap data-sentry-element="NickNameWrap" data-sentry-source-file="Chat.tsx">
        <ChatNickName label={name} data-sentry-element="ChatNickName" data-sentry-source-file="Chat.tsx" />
      </NickNameWrap>
      <TextWrap data-sentry-element="TextWrap" data-sentry-source-file="Chat.tsx">{filterPrivacy(chat.message)}</TextWrap>
    </ChatLayout>;
};
export { Chat };
export type { ChatProps };