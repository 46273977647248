import { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import { formateDateInLocaleOrder } from 'src/types/DateSelector';
import { LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { BirthdayInputProps } from './type';
import Button from 'src/components/Button';
import { defaultBirth } from 'src/stores/register/atoms';
import { DatePicker } from 'src/components/Datepicker';
const DateIndicator = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: ${({
  theme
}) => theme.color.green500};
  display: none;
  padding: 0;
  text-align: ${({
  theme
}) => theme.isRtl ? 'right' : 'left'};
  background-color: transparent;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: inline-block;
  }
`;
export const MobileDatePickerWrap = styled.div`
  position: relative;
  display: none;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: flex;
  }
  width: 100%;
`;
export const MobileBirthdayInputTypeA = ({
  onSubmit
}: BirthdayInputProps) => {
  const [birth, setBirth] = useState<Date>(defaultBirth);
  const {
    t
  } = useTranslation();
  const [isBirthChanged, setIsBirthChanged] = useState(false);
  const onChange = (date: Date) => {
    setBirth(date);
    setIsBirthChanged(true);
  };
  return <>
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">
        <LoginModalHeader data-sentry-element="LoginModalHeader" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">
          <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">{t('SIGNUP__INFO_BIRTHDAY')}</ModalTitle>
        </LoginModalHeader>
        <DateIndicator data-sentry-element="DateIndicator" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">{formateDateInLocaleOrder(birth)}</DateIndicator>
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">
        <MobileDatePickerWrap data-sentry-element="MobileDatePickerWrap" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">
          <DatePicker onChange={onChange} value={birth} data-sentry-element="DatePicker" data-sentry-source-file="MobileBirthdayInputTypeA.tsx" />
        </MobileDatePickerWrap>
        <Button onClick={() => onSubmit(birth)} disabled={!isBirthChanged} id='SIGNUP__NEXT_REGISTER_BIRTHDAY_STEP' data-testid='RegisterBirthdayStep-button-next' data-sentry-element="Button" data-sentry-source-file="MobileBirthdayInputTypeA.tsx">
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>;
};